import React, { useState } from "react";
var response = "Error";
const addressList = (props) => {
  const nextSet=(e)=>{
    //console.log(e)
    var destination= process.env.REACT_APP_DESTINATION;

    var array=[];
    array=e.split(":");
    
    //console.log(array, array[1],array[2])
    var xhr = new XMLHttpRequest();
  
    var url="/getPortDetails.do?addressReference="+array[1]+"&districtCode="+array[2]
    

    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4 && xhr.status == 200) {
        response = xhr.response;
        //console.log(response);
        //response = xmlData;
        var XMLParser = require("xml-js");
        var options = {
          ignoreComment: true,
          alwaysChildren: true,
          addParent: true,
          compact: false
        };
        //console.log("json convert")
        var result = XMLParser.xml2json(response, options);
         // or convert.xml2json(xml, options)
        //console.log("result is--->", result);
        const obj = JSON.parse(result);

        if( obj.elements[0].elements[0].name == "MessageType" &&
    obj.elements[0].elements[0].elements[0].text =="Error"){
  
      var message= obj.elements[0].elements[2].elements[0].text;
      props.showPostResultComp(true,message);
    }
    else if(obj.elements[0].elements[0].name == "MessageType" &&
    obj.elements[0].elements[0].elements[0].text =="Success"){
    //design error component with the message that gets from servelet call i.e 
   
    props.showPostResultComp(false,obj);
    }
    else if(obj.elements[0].elements[0].name == "portCount"){
    //design error component with the message that gets from servelet call i.e 
   
    props.showPostResultComp(false,obj);
    }
        
      }
      else if(xhr.status == 500 || xhr.status == 400){
        //console.log("inside error status code address list")
        var message= "Application error occurred. Please close your browser and try again after sometime."
        props.showPostResultComp(true,message);
      }
    };

    xhr.open(
      "GET",
      url,
      false
    );
    xhr.withCredentials = false;
    xhr.send();



    
  };

  return (
    <ul className="list-group mb-2">
      {props.addressArray.map((data) => (
        <a onClick={() => nextSet(data)} ><li className="list-group-item" style={{fontSize:12,cursor:'pointer'}}>{data}</li></a>
      ))}
    </ul>
  );
};
export default addressList;
