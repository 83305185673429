import React, { useState } from "react";

const pagination = ({addressPerPage,totalAddress,paginate}) => {
    const pageNumbers=[];
    for (let i=1 ;i<=Math.ceil(totalAddress/addressPerPage);i++){
        pageNumbers.push(i)
    }
  return (
<nav>
    <ul className="pagination">
{pageNumbers.map(number =>
    (<li key={number} className="page-item">
        <a onClick={()=>paginate(number)} href='#'className="page-link">
            {number}
        </a>
    </li>
    ))}
    </ul>
</nav>
  );
};
export default pagination;
