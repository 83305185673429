import React, { Component } from "react";

import DCarousel from "../../Components/DCarousel";
import { Link } from "react-router-dom";
import Para from "../../Components/CenterPara";

import { Modal } from "react-bootstrap";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState((state) => ({
      show: !state.show,      
    }));
  }

  render() {
    return (
      <div className="row" style={{ marginTop: 55, marginLeft: 40 }}>
        <div className="col-md-6 col-sm-12 " style={{ height: 400 }}>
          <Para fontSize="27px" fontWeight={700}>
            BT Wholesale
          </Para>
          <Para color="#FF90FF" fontSize="27px" fontWeight={700}>
            BroadBand Performance Test
          </Para>
          <Para>
            This broadband Performance Tester allows you to test the performance
            <br />
            of your broadband connection.
            <br />
            <br />
            If you are testing 500Mb/1000Mb product speeds please{" "}
            <button
              className="clickHereBtn"
              onClick={this.handleClose}
            >
              click here
            </button>
            .
            <br />
          </Para>
          <Link to="/details">
            <button className="testButton">GO</button>
          </Link>
        </div>
        <div
          // classname="col-md-6 col-sm-12"
          style={{
            width: 500,
            opacity: 0.9,
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <DCarousel onShowPopup={this.handleClose}/>
        </div>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          style={{ maxWidth: "100%" }}
        >
          <div
            style={{ width: "100%", marginTop: "10px", paddingRight: "10px" }}
          >
            <button
              onClick={this.handleClose}
              className="rectangleGuidance"
              style={{
                float: "right",
                fontWeight: "bold",
                textAlign: "end",
                margin: "auto",
              }}
            >
              [CLOSE]
            </button>
            <br />
            <p
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              When testing 500Mb/1000Mb speeds via a computer based device the
              results are dependent on the capability of the device and the
              premises environment.
              <br />
              In addition to the standard guidance provided in the ‘Before You
              Start?’ section you need to ensure that the customers device meets
              the following minimum specification:{" "}
            </p>
            <ol
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              <li>
                Processor should be above or equivalent to Intel® Core™ i5-4570S
                Processor (4 cores, 3.80 max Turbo frequency, 2.90GHz processor
                frequency, 6MB smart cache){" "}
              </li>
              <li>Ensure that at least 4 GB of RAM is free</li>
              <li>1 GB NIC card is recommended</li>
              <li>
                Ethernet cable should have the capability to transfer 1GB data{" "}
              </li>
              <li>Operating System - Windows 8 and above</li>
              <li>
                Minimum browsers supported - Google Chrome Version86, CX's
                Internet Explorer 11 as well as Microsoft Edge 2020 browsers
                version9 (2018 IE is not compatible){" "}
              </li>
              <li>
                Please ensure you are using the latest browser version for
                optimum results.{" "}
              </li>
            </ol>
            <p
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              {" "}
              NB: To ensure accurate results please ensure you are testing via a
              wired connection with all other devices disabled, or switched off
              when running the test.
            </p>
            <br />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Home;
