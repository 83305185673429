import React, { useState } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar'
import LoadingProgress from 'react-js-loading-progress-bar';

const TapTest = () => {
//   const [style,setStyle]=useState({width:`${0}%`});
// setTimeout(()=>{
// const newStyle={
//     opacity:1,
//     width:`${done}%`,
//     transition: `${"width 60s"}`
// }
// setStyle(newStyle)
// },80)
    return (
      <div className="col-md-12" >
      <div className="card mb-3" style={{height:200,boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)"}}>
<div className="card-header"><h6 style={{color:"#3C0E92",fontWeight:"bold"}}>Please wait while your Results are being loaded</h6></div>
<div className="card-body"style={{color:"#3C0E92",fontWeight:"bold",textAlign:"center"}}>
<h6 className="card-title" >Now testing for your Tap1 Service</h6>
<div className="loader"></div>
<div className="loader"></div>
<div className="loader"></div>
<div className="loader"></div>
</div>
</div></div>
    );
};

export default TapTest;