import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
color:${props=> props.color || '#FFFFFF'};
font-size:${props => props.fontSize || '13px'};
font-family: ${props => props.fontFamily || 'Century Gothic'};
font-weight: ${props => props.fontWeight}`


export default Div;


