import * as actionTypes from './actions';

const initialState ={
   JSON:''
  
}

const reducer =(state =initialState,action)=>{
    switch(action.type){
        case actionTypes.JSON:
            return{
                ...state,
                JSON:action.val
            }
       
    }

    return state;

};

export default reducer;