import React, { Component } from "react";
import $ from 'jquery';

import Box from "../src/Components/Box";
import InitiateFurtherDiagnostics from "../src/Pages/InitiatFurtherDiagnostics";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import completeImage from "../src/Assets/checked.png";
import downloadIcon from "../src/Assets/downloadIcon.png";
import initialImage from "../src/Assets/initialImage.gif";
import pingIcon from "../src/Assets/pingIcon.png";
import query from "query-string";
import uploadIcon from "../src/Assets/uploadIcon.png";
import { withRouter } from "react-router";
import intermediateImage from "../src/Assets/intermediate.png"

var sTime;
var arrUpData1 = [];
var Download_complete = 0;
var downspeed = 0,
  upspeed = 0,
  bytes = 0,
  start = 0,
  end = 0,
  startUpTime = 0,
  endUpTime = 0,
  uploadSize = 0;
var max_UP_speed = 0;
var startTime, endTime, myid;
var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
myid ="?id=" + myid + possible.charAt(Math.floor(Math.random() * possible.length));
var downloadSize = [62914560];
var uploadSize = [450877];
var processing = 0;
var prevLoaded = 0;
var count_download = 0;
var bitsLoaded = 0;
var interval = null;
var graceTimeDone = false;
var time_dl = 15;
var downloadFlag=0;
var download = [];
var array=[];
var downloadTime=5000;
var dlProgress = null;
var time_dlGraceTime = 1.5;
var elem = null;
var elem1 = null;
var arr = [];
var i = 0;

var count_Upload = 0;
var arrUpTime = [];
var arrUpData = [];
var arrUpData1 = [];
var upsize = 0;

var upcnt = 0;
var myMap = new Map();
//var arrUpData1=[] ;
var arrUpSpeed = [];
var latencyCnt = 0;
var minLatencyValue;
var timeOutValue = 4000;
var speedSlab = 0;
var data = 61275;
var myVar;
var myData;
var incData  = 0;
var tempData = 0;
var InitialuploadSpeed;

var urlupload =process.env.REACT_APP_UPLOAD_URL;
var latencyURL=process.env.REACT_APP_PING_URL;
var downloadURL=process.env.REACT_APP_DOWNLOAD_URL;
var cnt = 1;
var downaloadReceivedLength=0;
var flag = 0;
var uploadFlag = 0;
var arrLatency = [];


var numcnt;

var max_speed = 0;
var latencyResut = 0;
// Create a ES6 class component  10

var sTime;
var downloadedDataInCall1 = 0;
var downloadedDataInCall2 = 0;
var downloadedDataInCall3 = 0;
var downloadedDataInCall4 = 0;
var dataDownloaded = 0;
var startTimeCaptured = 0;
var startTime = 0;
var speedEa=0;
class Sample extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      box: [
        { name: "PING", img: pingIcon, value: "--", unit: "ms" },
        { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
        { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
      ],

      ShowFurtherDiagnosticsComponent: false,
      dn: 0,
      dataList: [],
      timeList: [],
      error: null,
      results: [],
      initialMessage: "",
      showFurtherDiagnostics: false,
      
    };
    this.setStateDown = this.setStateDown.bind(this);
    this.delay = this.delay.bind(this);
    this.test_upload = this.test_upload.bind(this);
  }

  renderTooltip = props => {
    return (
      <Tooltip {...props}>
        If you're not getting the speed test result you were expecting you can
        run an Additional Diagnostic test by clicking the button to the left.
      </Tooltip>
    );
  };
  componentWillMount(){

    axios.get(
      latencyURL+"?_=" +
      new Date().getTime())
      this.setState({
        initialImage:intermediateImage
      })


  }

  async componentDidMount() {
  
    const search = query.parse(this.props.location.search);
    if (Object.keys(search) && Object.keys(search).length > 0) {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: search.ping || "--",
            unit: "ms"
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: search.download || "--",
            unit: "Mbps"
          },
          {
            name: "UPLOAD",
            img: uploadIcon,
            value: search.upload || "--",
            unit: "Mbps"
          }
        ],
        bytes: parseInt(search.bytes || "0"),
        end: parseInt(search.end || "0"),
        endUpTime: parseInt(search.endUpTime || "0"),
        start: parseInt(search.start || "0"),
        startUpTime: parseInt(search.startUpTime || "0"),
        uploadSize: parseInt(search.uploadSize || "0"),
        ShowFurtherDiagnosticsComponent: false,
        showFurtherDiagnostics: true,
        initialImage: completeImage
      });
    } else {
      await axios.get(
        latencyURL+"?_=" +
        new Date().getTime()
      
      );
     
      await setTimeout(() =>console.log("hi"), 2000);
   
      await this.delay();
      await this.latency();
      
      this.setState({
        initialImage:intermediateImage
      })
      await this.delay();
      await this.calculateDownloadSpeed();
      this.setState({
        initialImage:intermediateImage
      })
     
    }
  }
  delay=()=>{
     axios.get(
      latencyURL+"?_=" +
      new Date().getTime()
    
    );

  }

  test_upload = () => {
   
    this.calculateData(data);
    myVar = setTimeout(this.alertFunc, 5000);
    this.start_upload_new("id" + cnt);

  };

  setStateDown=(data,speed)=>{
    console.log("setstate", data)
    this.setState({
      
      box: [
        {
          name: "PING",
          img: pingIcon,
          value: this.state.box[0].value,
          unit: "ms"
        },
        {
          name: "DOWNLOAD",
          img: downloadIcon,
          value: "--",
          unit: "Mbps"
        },
        { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
      ],
      value1: speed,
      value2: this.state.box[1].name,
      value3: this.state.box[1].unit
    });

    
  }
  setStateDownFinal=(downspeed,bytes,start,end)=>{
    this.setState({
      dataList: [],
      sp: downspeed,
      box: [
        {
          name: "PING",
          img: pingIcon,
          value: this.state.box[0].value,
          unit: "ms"
        },
        {
          name: "DOWNLOAD",
          img: downloadIcon,
          value: downspeed,
          unit: "Mbps"
        },
        { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
      ],
      value1: downspeed,
      value2: this.state.box[1].name,
      value3: this.state.box[1].unit,
      bytes: bytes,
      start: start,
      end: end
    });
  }
DownloadmethodStart=()=> {

    var arrTime = [];
    var arrData = [];

  //  console.log("array Time " + arrTime.length);
   // console.log("array Data " + arrData.length);
   const setStateDown=this.setStateDown
   const setStateDownFinal=this.setStateDownFinal
   const delay=this.delay
   const test_upload=this.test_upload
   window.$ = $;

    $(document).ready(function() {
        var cnt = 0;
        var d3 = $.Deferred();

        function startDownloadCall() {

            var startTime = 0;
            var dataloaded = 0;
            
            $.ajax({
                xhr: function() {
                    var xhr = new window.XMLHttpRequest();
                    //Download progress
                    xhr.addEventListener("progress", function(evt) {
                        if (evt.lengthComputable) {
                            dataloaded = evt.loaded;
                            var endIme=new Date().getTime();
                            var duration=  endIme-startTime;
                           var speedEach = ((dataloaded * 8) / 1000000 / (duration / 1000)).toFixed(2);
                            setStateDown(dataloaded,speedEach);
                     
                        }
                    }, false);
                    return xhr;
                },

                type: 'GET',
                url:  downloadURL+"?_=" +
                new Date().getTime(),
                cache: false,
                beforeSend: function() {
                    startTime = (new Date()).getTime();
           start = startTime;
                },
                timeout: timeOutValue,
                complete: function() {
                    end = new Date().getTime();
          var totalTime = end - startTime;
                    arrTime.push((totalTime));
                    arrData.push((dataloaded));
                
                    d3.resolve("asda");

                }
            });
        }




        $.when(startDownloadCall(), startDownloadCall(), startDownloadCall(), startDownloadCall(), startDownloadCall(), startDownloadCall(), startDownloadCall(), startDownloadCall()).done(
            function myFunction() {
                setTimeout(function() {
                    //console.log("n shw");
                    
                    var sumData = 0,
                        sumTime = 0;
                    for (var i = 0; i < arrData.length; i++) {


                        sumData += parseInt(arrData[i], 10); //don't forget to add the base
                        sumTime += parseInt(arrTime[i], 10); //don't forget to add the base
                    }

                   console.log('Final Sum == ' + sumData);
                    var avg = sumTime / arrTime.length;
          end = avg + start;

                   console.log('avg Time == ' + avg);
                    bytes = sumData;
               var     fileSize = ((sumData / 1000) / 1000);
                    var speedDwn = (fileSize * 8) / (avg / 1000);
                    console.log(speedDwn + " Mbps\n")
                    max_speed = (speedDwn).toFixed(2);
          downspeed=max_speed;
                    //arrData.length = 0;
                    //arrTime.length = 0;
                    processing = 0;
                    //                 calculateData(256000);
                    // calculateData(168960);
                    //calculateData(450560);
                    
                    setStateDownFinal(downspeed,bytes,start,end)
                
                    var startTime1 = 0;
                   delay();
                   test_upload();

                }, 5000);
            }
        );


    });
  
  
  



}


  calculateData = dataSizeInBytes => {
    myData = "d="; // the raw data you will send
    var possible =
      "ABCDEFGHIJRSTUVW6789XYZabcdefghij012345klmnopqrstuvwxyzKLMNOPQ";
    for (
      var i = 0;
      i < dataSizeInBytes;
      i++ //if you want to send 1 kb (2 + 1022 bytes = 1024b = 1kb). change it the way you want
    ) {
      myData += possible.charAt(Math.floor(Math.random() * possible.length)); // add one byte of data;
    }

    incData =myData;
  };

  start_upload_new = getUpId => {
  
    //console.log("start_upload_new");
    this.calculateDataTemp(10000);
   
    incData = tempData + new Date().getTime() + incData ;
    
    //console.log(incData.length);
    ////console.log("data==============================>  ", incData);

    if (uploadFlag == 0) {
      this.setState({
        initialImage:initialImage
      })
      var sTime, eTime;

      sTime = new Date().getTime();
      var config = {
        cache: false,
        timeout: 5000
      };

      return axios
        .post(
          urlupload + Math.random(),
          incData,
          config
        )
        .then(res => {
          //console.log(res);
          eTime = new Date().getTime();
          var bitsLoaded1 = incData.length;
          //console.log("bitsLoaded++++++>", bitsLoaded);
          var minusSeconds = eTime - sTime;
          var json = JSON.parse(JSON.stringify(res));
          //console.log("json", json);
          //console.log("uploadSizeBytes " + json.uploadSizeBytes);
          var a = res.data.uploadSpeedPerSecHumanReadable;
          var speedUPMbps_s = a.toFixed(2);
          //console.log("speedUPMbps_s " + speedUPMbps_s);
          var bitsLoaded1 = parseFloat(bitsLoaded1) * 8;
          var MiliSecTOSec = minusSeconds / 1000;
          var speedUPBps = bitsLoaded1 / MiliSecTOSec;
          var speedUPKbps = speedUPBps / 1000;
          var speedUPMbps = (speedUPKbps / 1000).toFixed(2);
          arrUpData1.push({
            id: getUpId,
            startTime: sTime,
            endTime: eTime,
            duration: minusSeconds,
            dataDownloaded: bitsLoaded1,
            ServerSpeed: speedUPMbps_s
          });
          cnt++;
          this.start_upload_new("id" + cnt);
          //console.log( "this.start_upload_new(+cnt)=================>","id" + cnt);
          if (uploadFlag == 0) {
            this.setState({
              box: [
                {
                  name: "PING",
                  img: pingIcon,
                  value: this.state.box[0].value,
                  unit: "ms"
                },
                {
                  name: "DOWNLOAD",
                  img: downloadIcon,
                  value: this.state.box[1].value,
                  unit: "Mbps"
                },
                { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
              ],
              value1: speedUPMbps_s,
              value2: this.state.box[2].name,
              value3: this.state.box[2].unit
            });
          }
        })
        .catch(error => {
          this.setState({
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms"
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: this.state.box[1].value,
                unit: "Mbps"
              },
              { name: "UPLOAD", img: uploadIcon, value: 0,unit: "Mbps" }
            ],
            value1: 0,
            value2: this.state.box[2].name,
            value3: this.state.box[2].unit,
            startUpTime: startUpTime,
            endUpTime: endUpTime,
            uploadSize: uploadSize,
            initialImage: completeImage,
            initialMessage: "Your Diagnostics Results are here",
            showFurtherDiagnostics: true
          });
        });
    }
  };

  calculateDataTemp = dataSizeInBytes => {
    tempData = "d="; // the raw data you will send
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (
      var i = 0;
      i < dataSizeInBytes;
      i++ //if you want to send 1 kb (2 + 1022 bytes = 1024b = 1kb). change it the way you want
    ) {
      tempData += possible.charAt(Math.floor(Math.random() * possible.length)); // add one byte of data;
    }
  };

  GetSortOrder = prop => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  alertFunc = () => {
    uploadFlag = 1;

    //console.log("arrUpData1 length initial  == " + arrUpData1.length);
    //console.log("arrUpData1  initial  == " + arrUpData1);

    if (arrUpData1.length > 0) {
      for (var item in arrUpData1) {
        //									//console.log(" arrUpData1 =" + arrUpData1[item].ServerSpeed );
    
      }

      //	$("#textshow").hide();

      arrUpData1.sort(this.GetSortOrder("ServerSpeed"));
      //console.log("arrUpData1 length  == " + arrUpData1.length);

      var bottemRem = Math.trunc(arrUpData1.length * (15 / 100));

      //console.log("Removing 15% High   == " + bottemRem);

      /*if(bottemRem < 1 && arrUpData1.length > 1){
             bottemRem = 1;
             }
             */
      ////console.log('Removing 5% High new  == ' + bottemRem);

      var TopRem = Math.trunc(arrUpData1.length * (50 / 100));
      //console.log("Removing 50% Low  == " + TopRem);

      arrUpData1.length = arrUpData1.length - bottemRem;
      //console.log("After Removing 15% High  == " + arrUpData1.length);

      arrUpData1.splice(0, TopRem);
      //console.log("After Removing 50% Low  == " + arrUpData1.length);

      var sumupSpeed = 0;

      var sum = 0;
      for (var item in arrUpData1) {
        sumupSpeed += parseFloat(arrUpData1[item].ServerSpeed, 10);
        sum = sum + arrUpData1[item].dataDownloaded;
      }

      var avgSpeed = (sumupSpeed / arrUpData1.length).toFixed(2);

      //console.log("Upload speed is = " + avgSpeed);

      max_UP_speed = avgSpeed;
      if (max_UP_speed === undefined || isNaN(max_UP_speed)) {
        max_UP_speed = 0.0;
      }

      if (sum === undefined || isNaN(sum)) {
        uploadSize = myData.length;
      } else {
        uploadSize = ~~sum;
      }

      //console.log("Max Speed == " + max_UP_speed);
      upspeed = max_UP_speed;

      var updCnt = parseInt(arrUpData1.length);
      startUpTime = arrUpData1[0].startTime;
      endUpTime = arrUpData1[0].endTime;
      //console.log("Final upspeed is = " + upspeed);
      var maxSP = "YOUR UPLOAD SPEED IS :" + upspeed + " Mbps";
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms"
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: this.state.box[1].value,
            unit: "Mbps"
          },
          { name: "UPLOAD", img: uploadIcon, value: upspeed, unit: "Mbps" }
        ],
        value1: upspeed,
        value2: this.state.box[2].name,
        value3: this.state.box[2].unit,
        startUpTime: startUpTime,
        endUpTime: endUpTime,
        uploadSize: uploadSize,
        initialImage: completeImage,
        initialMessage: "Your Diagnostics Results are here",
        showFurtherDiagnostics: true
      });

      if (upspeed != 0.0) {
        var endUpTimeVar = (uploadSize * 8) / upspeed + startUpTime;
        endUpTime = parseInt(endUpTimeVar);
      }

      if (downspeed != 0.0) {
        var endVar = (bytes * 8) / downspeed + start;
        end = parseInt(endVar);
      }

      //console.log("Get Speed Method");
 
    } else {
      //console.log("Get Speed Method");
    }

    //;
  };

  async latency() {
    var letenctStartTime1 = new Date().getTime();
    this.setState({
      initialImage:initialImage
    })
    //console.log("start latency");
    var arrLatency = [];
    await axios({
      method: "get",
      url:
      latencyURL+"?_=" +
        new Date().getTime(),
      timeout: 5000,
      cache: false
    })
      .then(data => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime1);
      
        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency1----------->", avgLatency);
        var latencyResut1 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut1----------->", latencyResut1);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut1, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: latencyResut1,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit
        });
      })
      .catch(error => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit
        });
      });

    var letenctStartTime2 = new Date().getTime();
    axios({
      method: "get",
      url:
      latencyURL+"?_=" +
        new Date().getTime(),
      timeout: 5000,
      cache: false
    })
      .then(data => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime2);
       
        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency2----------->", avgLatency);
        var latencyResut2 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut2----------->", latencyResut2);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut2, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: latencyResut2,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit
        });
      })
      .catch(error => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit
        });
      });

    var letenctStartTime3 = new Date().getTime();
    axios({
      method: "get",
      url:
      latencyURL+"?_=" +
        new Date().getTime(),
      timeout: 5000,
      cache: false
    })
      .then(data => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime3);
        
        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency3----------->", avgLatency);
        var latencyResut3 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut3----------->", latencyResut3);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut3, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: latencyResut3,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit
        });
      })
      .catch(error => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit
        });
      });

    var letenctStartTime4 = new Date().getTime();
    axios({
      method: "get",
      url:
      latencyURL+"?_=" +
        new Date().getTime(),
      timeout: 5000,
      cache: false
    })
      .then(data => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime4);
     
        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency4----------->", avgLatency);
        var latencyResut4 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut4----------->", latencyResut4);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut4, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: latencyResut4,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
          
        });
      })
      .catch(error => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit
        });
      });
  }

  async calculateDownloadSpeed() {
    this.setState({
      initialImage:initialImage
    })
    const { detect } = require('detect-browser');
const browser = detect();
 
// handle the case where we don't detect the browser
console.log(browser.name)
if (browser.name !="edge") {
 
  console.log(browser.name)
    var maxSpeed = 0;
    var speeda = [];
    var speedEach = 0;
    var sumData = 0;
    var sumTime = 0;
    var maxSpeedArray = [];
    var downloadSpeed = 0;
    sTime = new Date().getTime();
    //console.log("Start Time:->" + sTime);
    await axios
      .all([this.getData1(), this.getData2(), this.getData3(), this.getData4()])
      .then(data => {
        if (startTimeCaptured >= 15) {
          var eTime = new Date().getTime();
          var maxSpeedArray = [];
          if (typeof this.state.sp1 !== "undefined") {
            maxSpeedArray.push(this.state.sp1);
          }
          if (typeof this.state.sp2 !== "undefined") {
            maxSpeedArray.push(this.state.sp2);
          }
          if (typeof this.state.sp3 !== "undefined") {
            maxSpeedArray.push(this.state.sp3);
          }
          if (typeof this.state.sp4 !== "undefined") {
            maxSpeedArray.push(this.state.sp4);
          }
          var speedMax = Math.max(...maxSpeedArray);
          if (typeof this.state.dt1 !== "undefined") {
            sumData = sumData + this.state.dt1;
          }
          if (typeof this.state.dt2 !== "undefined") {
            sumData = sumData + this.state.dt2;
          }
          if (typeof this.state.dt3 !== "undefined") {
            sumData = sumData + this.state.dt3;
          }
          if (typeof this.state.dt4 !== "undefined") {
            sumData = sumData + this.state.dt4;
          }

          var duration = eTime - startTime;
          //var duration = 3000;
          speedEach = ((sumData * 8) / 1000000 / (duration / 1000)).toFixed(2);
          //console.log("Duration is :->", duration);
          //console.log("SumData is :->", sumData);
          var speed = speedEach;

          this.setState({
            dataList: [],
            sp: speed,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms"
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: speed,
                unit: "Mbps"
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit,
            bytes: sumData,
            start: sTime,
            end: eTime
          });
        this.delay();
      this.test_upload();
        }
      })
      .catch(error => {
        var eTime = new Date().getTime();
        var maxSpeedArray = [];
        if (typeof this.state.sp1 !== "undefined") {
          maxSpeedArray.push(this.state.sp1);
        }
        if (typeof this.state.sp2 !== "undefined") {
          maxSpeedArray.push(this.state.sp2);
        }
        if (typeof this.state.sp3 !== "undefined") {
          maxSpeedArray.push(this.state.sp3);
        }
        if (typeof this.state.sp4 !== "undefined") {
          maxSpeedArray.push(this.state.sp4);
        }
        var speedMax = Math.max(...maxSpeedArray);
        if (typeof this.state.dt1 !== "undefined") {
          sumData = sumData + this.state.dt1;
        }
        if (typeof this.state.dt2 !== "undefined") {
          sumData = sumData + this.state.dt2;
        }
        if (typeof this.state.dt3 !== "undefined") {
          sumData = sumData + this.state.dt3;
        }
        if (typeof this.state.dt4 !== "undefined") {
          sumData = sumData + this.state.dt4;
        }

        var duration = eTime - startTime;
        //var duration = 3000;
        speedEach = ((sumData * 8) / 1000000 / (duration / 1000)).toFixed(2);
        //console.log("Duration is :->", duration);
        //console.log("SumData is :->", sumData);
        var speed = speedEach;

        this.setState({
          dataList: [],
          sp: speed,
          box: [
            {
              name: "PING",
              img: pingIcon,
              value: this.state.box[0].value,
              unit: "ms"
            },
            {
              name: "DOWNLOAD",
              img: downloadIcon,
              value: 0,
              unit: "Mbps"
            },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
          ],
          value1: 0,
          value2: this.state.box[1].name,
          value3: this.state.box[1].unit,
          bytes: sumData,
          start: sTime,
          end: eTime
        });
      });
    
    }
    else{
      await this.DownloadmethodStart();
      
    }
   
    
  }

  getData1() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      timeout: 5000,
      url:
      downloadURL+"?_=" +
        new Date().getTime(),
      cache: false,

      onDownloadProgress: progressEvent => {
        var eTime = new Date().getTime();

        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall1 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall1 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall1 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData1:->" + speed);
          speed2Array.push(speed);
          var speedArrayMax = Math.max(...speed2Array);
          dataListArray.push(downloadedDataInCall1);

          var data = Math.max(...dataListArray);
          //				//console.log("timealist getdata :",timeListArray);
          this.setState({
            dataList1: dataListArray,
            dt1: data,
            sp1: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms"
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps"
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit
          });
        }
      }
    }).catch(error => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms"
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps"
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit
      });
      
    });
  }
  getData2() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      timeout: 5000,
      url:
      downloadURL+"?_=" +
        new Date().getTime(),
      cache: false,

      onDownloadProgress: progressEvent => {
        var eTime = new Date().getTime();
        //console.log("EndTime getData2:->" + eTime);
        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall2 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall2 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall2 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData2:->" + speed);
          speed2Array.push(speed);
          var speedArrayMax = Math.max(...speed2Array);

          dataListArray.push(downloadedDataInCall2);

          var data = Math.max(...dataListArray);
          this.setState({
            dataList2: dataListArray,
            dt2: data,
            sp2: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms"
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps"
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit
          });
        }
      }
    }).catch(error => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms"
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps"
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit
      });
    });
  }
  getData3() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      timeout: 5000,
      url:
      downloadURL+"?_=" +
        new Date().getTime(),
      cache: false,

      onDownloadProgress: progressEvent => {
        var eTime = new Date().getTime();
        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall3 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall3 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall3 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData3:->" + speed);

          speed2Array.push(speed);
          var speedArrayMax = Math.max(...speed2Array);

          dataListArray.push(downloadedDataInCall3);

          var data = Math.max(...dataListArray);
          //				//console.log("timealist getdata :",timeListArray);
          this.setState({
            dataList3: dataListArray,
            dt3: data,
            sp3: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms"
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps"
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit
          });
        }
      }
    }).catch(error => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms"
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps"
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit
      });
    });
  }
  getData4() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      timeout: 5000,
      url:
      downloadURL+"?_=" +
        new Date().getTime(),
      cache: false,

      onDownloadProgress: progressEvent => {
        var eTime = new Date().getTime();
        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall4 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall4 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall4 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData4:->" + speed);
          speed2Array.push(speed);

          var speedArrayMax = Math.max(...speed2Array);

          dataListArray.push(downloadedDataInCall4);

          var data = Math.max(...dataListArray);
          //				//console.log("timealist getdata :",timeListArray);
          this.setState({
            dataList4: dataListArray,
            dt4: data,
            sp4: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms"
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps"
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit
          });
        }
      }
    }).catch(error => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms"
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps"
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" }
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit
      });
    });
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center" >
        <div className='col-md-3 col'></div>
        <div className="col-md-10 col-xs-12 card space-for-header space-for-ie full-width" style={{ background: '#5013AB', color: 'white' }}>
          <div className="card-body">
            <div className="row">
              {/* full rocket section */}
              <div className="col-md-5 col-sm-12 " style={{}}>
                <div className="row">
                  <div className="col-sm-12">
                    <h6>Basic Speed Test</h6>
                  </div>

                  <div className="col-6 col-sm-6 col-md-12">
                    <img
                    className="smallScreen bigScreen"
                      src={this.state.initialImage}
                      title={this.state.initialMessage}
                      
                    />
                  </div>

                  <div className="col-6 col-md-12">
                    
                      <div
                        className={`col-sm-12 ${this.state.showFurtherDiagnostics && "green"}`}
                      >
                        {this.state.showFurtherDiagnostics === false
                          ? <h6 className="value-css value-csss">{this.state.value1} {this.state.value3} </h6>
                          : <span  className="successTestInitial">Broadband Speed Test</span>}
                      </div>
                      <div
                        className={`col-sm-12 ${this.state.showFurtherDiagnostics && "green"}`} 
                      >
                        {this.state.showFurtherDiagnostics === false
                          ? <h6 style={{marginLeft:65,fontWeight:'bold',color:'#FF90FF'}}></h6>
                          : <span className="successTestInit">Executed Successfully</span>}
                      </div>
                  
                  </div>
                </div>
              </div>

              {/* feed boxes */}
              <div className="col-md-7 col-sm-12">
                <div className="row">
                  <Box
                    name={this.state.box[0].name}
                    value={this.state.box[0].value}
                    unit={this.state.box[0].unit}
                    img={this.state.box[0].img}
                    className="col-md-4 col-sm-6 col-12"
                  />
                  <Box
                    name={this.state.box[1].name}
                    value={this.state.box[1].value}
                    unit={this.state.box[1].unit}
                    img={this.state.box[1].img}
                    className="col-md-4 col-sm-6 col-12"
                  />
                  <Box
                    name={this.state.box[2].name}
                    img={this.state.box[2].img}
                    value={this.state.box[2].value}
                    unit={this.state.box[2].unit}
                    className="col-md-4 col-sm-6 col-12"
                  />
                </div>
                {this.state.showFurtherDiagnostics === true ?
                (<div className="row" style={{display:'flex', marginLeft:0}}>
                  <div className="col-xs-5">
                    {this.props.location.search && Object.keys(query.parse(this.props.location.search)).length>0?
                    (
                      <a href="/details">
                        <button className="circle">
                          AGAIN
                        </button>
                      </a>
                    ):<button className="circle" onClick={()=>window.location.reload(true)}>
                      AGAIN
                    </button>
                    }
                  </div>
                  <div className="col-xs-1 line" style={{position:"relative"}}></div>
                  <div className="col-xs-6 ">
                    <Link to={`/initiateFurtherDiag?ping=${this.state.box[0].value}&download=${this.state.box[1].value}&upload=${this.state.box[2].value}&bytes=${this.state.bytes}&start=${this.state.start}&end=${this.state.end}&startUpTime=${this.state.startUpTime}&endUpTime=${this.state.endUpTime}&uploadSize=${this.state.uploadSize}`}><button className="rectangle">Additional Diagnostic</button></Link>
                 <OverlayTrigger placement="right" delay={{show:250, hide:400}} overlay={this.renderTooltip}><button className="hoverCircle">?</button></OverlayTrigger>
                  </div>
                </div>):(<div className="row"></div>)}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3 col'></div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default withRouter(Sample);