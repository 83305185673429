import { Nav, Navbar } from 'react-bootstrap';

import React from 'react';
import logo from "../../Assets/btwholesale-header-logo.svg";
import styled from 'styled-components';

const Styles = styled.div`

    .navbar{
        float:left;
        background-color: none;
        
    }

    .navbar-brand{
        vertical-align:-11px;
        padding-bottom: .9125rem;
        padding-top:.1125rem;
        margin: 0px 0px 0px 0px;
        margin-right:50px;
        margin-left:-30px;

    } 
    .navbar-nav .nav-link{
        color:#FFFFFF;
        
        
        &:hover{

            color:white;
            font-weight:500;
            border-bottom: solid 2px #FF90FF;
        }


    }`;

export const NavigationBar = () => (

    <Styles>
        <Navbar expand="md">
            <Navbar.Brand href="/"><img src={logo} alt="Logo" style={{ paddingLeft: 0 }}></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="md-auto">
                    <Nav.Item>
                        <Nav.Link href="/">Home</Nav.Link>

                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/guidance">SpeedTest Guidance</Nav.Link>

                    </Nav.Item>

                </Nav>

            </Navbar.Collapse>
        </Navbar>

    </Styles>


)

export default NavigationBar;