import React, { useState,useEffect } from "react";
import PortList from '../PortList'
import Pagination from '../Pagination'
var currPage;

const PortComponent = ({portArray,portCount,ONTReference,showPortResultComp}) => {
    const [currentPage, setCurrentPage] = useState(1);
    
    const  [portPerPage] =useState(10);

const indexofLastPort=currentPage*portPerPage;
const indexofFirstPort=indexofLastPort-portPerPage;
    // var lastPageNo;
    // var indexofLastAddress;
    // var indexofFirstAddress;
    
    // var currentAddress=[];
    // var reminder = totalAddress % addressPerPage;

    // if (reminder >  0)
    // {
    //   lastPageNo = (totalAddress / addressPerPage) + 1;
    // }
    // else
    // {
    //    lastPageNo = totalAddress / addressPerPage;
       
    // }
    // lastPageNo=Math.floor(lastPageNo); 
    // //console.log("last page is->",lastPageNo)
    // //console.log("set currnt page page is->",currPage)
    // if (currPage == lastPageNo)
    // {
    //   //console.log("if->",lastPageNo,totalAddress,reminder)
    //   indexofLastAddress = totalAddress;
    //   //console.log("if lastindex->",totalAddress)
    //   indexofFirstAddress = indexofLastAddress-(reminder+1);
    //   //console.log("if firstindex->",indexofFirstAddress)
    // }
    // else
    // {//console.log("else->",lastPageNo,totalAddress,reminder)
    //   indexofLastAddress =currentPage *addressPerPage;
    //   //console.log("if lastindex->",totalAddress)
    //   indexofFirstAddress = indexofLastAddress-addressPerPage;
    //   //console.log("if firstindex->",indexofFirstAddress)
    // }
     const newPortArray= portArray.slice(0,portCount);
     //console.log(newPortArray)
    const currentPort = newPortArray.slice(indexofFirstPort,indexofLastPort);
    //console.log("addres on current page are---->",currentPort)

    const paginate=(pageNumber)=>
    {
    setCurrentPage(pageNumber);
    currPage = pageNumber;
    }
    
  return (
      <div>
          <Pagination addressPerPage={portPerPage} totalPort={portCount} paginate={paginate}/>
<PortList portArray={currentPort} ONTReference={ONTReference} showPortResultComp={showPortResultComp} />
      </div>
  );
};
export default PortComponent;
