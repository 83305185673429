import React, { useState } from "react";

const SendingResultsToServer = ({done}) => {
    const [style,setStyle]=useState({width:`${10}%`});
    setTimeout(()=>{
    const newStyle={
        opacity:1,
        width:`${done}%`,
        transition: `${"width 4s"}`
    }
    setStyle(newStyle)
    setStyle(newStyle)
    },80)
    return (
        
        <div class="col-md-12" >
        <div class="card border-primary mb-3" style={{height:200}}>
<div class="card-header"><h4>Sending Result to Server.....</h4></div>
<div class="card-body"style={{color:'white'}}>
 <h5 class="card-title" >Now testing for your Tap1 Service</h5>
 <p class="card-text">
 <div class="progress">
        <div id="dynamic" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="10" aria-valuemax="100" style={style}>
          <span id="current-progress"></span>
        </div>
      </div>
 </p>
</div>
</div></div>
    );
};

export default SendingResultsToServer;