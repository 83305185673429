import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ErrorPage from "../src/Assets/404.png"


class NotFoundPage extends Component{
    render(){
        return (
        <div>
                    <div className="col-12" >
          <div className="card" style={{marginTop:30,opacity:0.95}}>
<img src={ErrorPage}style={{height:400}}></img>
            <div className="card-body">
            <p style={{textAlign:"center" ,color:"#3C0E92"}}>
                <h4 style={{}}>Ah Snap!!!! We could not find the requested URL. Please Check the entered url or Navigate to Homepage by clicking the link above.</h4>
              
            </p>
            </div>
          </div>

        </div>
         
           
          </div>);
    }
}export default NotFoundPage;