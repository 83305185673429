import React, { useState } from "react";


var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
var day    = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

var tempDate = new Date();
  var date = day [tempDate.getDay()] +' '+tempDate.getDate() + ' ' + months[tempDate.getMonth()] + ' ' + tempDate.getFullYear() +' | '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
  

const showPostResultType1 = props => {
  return (
    <div>
       <div className="row">
        <div className="col-md-12">
          <div className="card  mb-3" style={{ borderColor: "#FF90FF" }}>
            <div
              className="col-12"
              style={{ color: "#3C0E92", fontWeight: "bold", fontSize: 15 ,marginTop:8}}
            >
              Summary of Performance Test
            </div>
            <div
              className="row"
              style={{ marginLeft: 1, marginBottom: 8, color: "#3C0E92" }}
            >
              <div className="col-6 margin-ie font-space-for-result-summary" >
                Timestamp
                <div  className ="style-for-div-summary">
                  {date}
                </div>
              </div>
              <div className="col-2 margin-ie font-space-for-result-summary" >
                Ping
                <div className ="style-for-div-summary" >{props.ping} Ms</div>
              </div>
              <div className="col-2 margin-ie font-space-for-result-summary" >
                Download
                <div className ="style-for-div-summary">{props.download} Mbps</div>
              </div>
              <div className="col-2 margin-ie font-space-for-result-summary" >
                Upload
                <div className ="style-for-div-summary">{props.upload} Mbps</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col-md-12">
          <div class="card mb-3" style={{ borderColor: "#FF90FF" }}>
          <div className="row" style={{ marginLeft: 0 ,marginRight:0}}>
            <div
              class="col-12"
              style={{ color: "#3C0E92", fontWeight: "bold", fontSize: 15 ,marginTop:8}}
            >
              Summary Advanced Test
            </div>
            </div>
            <div className="row" style={{ marginLeft: 0 ,marginRight:0}}>
            <div
              class="col-12"
              style={{ marginLeft: 1, marginBottom: 8, color: "#3C0E92" ,fontSize:12}}
            >
              <p>
              {props.result}
              </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default showPostResultType1;
