import React, { Component } from "react";
import '../SpeedTestGuidance/index.css'
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

class SpeedTestGuidance extends Component {
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    return (
      
        <div className="row" id='top'>
          <div className="col-1"></div>
          <div className="col-10 class"
            
            style={{
              
              background: "#FFFFFF",
              
              opacity: 0.88,
              borderRadius: 0.9,
              marginTop:80, 
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
          >
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                marginTop: 30,
                fontSize: 20,
                fontWeight: "bold"
              }}
             
            >
              <button
                onClick={() => this.props.history.goBack()}
                className="rectangleGuidance"
                style={{ float: "right" ,fontWeight:'bold' }}
              >
                [CLOSE]
              </button>
              Frequently Asked Questions
            </h1>
            <br />
            <p
              style={{
                color: "#5717B4",
                marginLeft: 20,
                fontSize: 15,
                fontWeight: "bold"
              }}
            >
              <a href='#q1' style={{textDecoration:"underline",color:"#5717B4"}}>1. What does the Broadband Performance Tester do?</a>
              <br />
              <a href='#q2' style={{textDecoration:"underline",color:"#5717B4"}}>2. What tests does the Broadband Performance Tester complete?</a>
              <br />
              <a href='#q3' style={{textDecoration:"underline",color:"#5717B4"}}>3. What types of faults or problems can the Broadband Performance
              Tester help me with?</a>
              <br />
              <a href='#q4' style={{textDecoration:"underline",color:"#5717B4"}}>4. What are the pre-requisites for using the Broadband Performance
              Tester?</a>
              <br />
              <a href='#q5' style={{textDecoration:"underline",color:"#5717B4"}}>5. What do the terms shown in the test result screens mean?</a>
              <br />
              <a href='#q6' style={{textDecoration:"underline",color:"#5717B4"}}>6. What is a Broadband Service ID or BBEU?</a>
              <br />
              <a href='#q7' style={{textDecoration:"underline",color:"#5717B4"}}>7. Do I always need to enter a Broadband Service ID?</a>
              <br />
              <a href='#q8' style={{textDecoration:"underline",color:"#5717B4"}}>8. What is an Access line ID or ALID?</a>
              <br />
              <a href='#q9' style={{textDecoration:"underline",color:"#5717B4"}}>9. Which identifier should I to use – Broadband Service ID, Access Line ID or Telephone Number?</a>
              <br />
              <a href='#q10' style={{textDecoration:"underline",color:"#5717B4"}}>10. What should I do if I have difficulty running the Broadband
            Performance Tester?</a>
            <br/>
            <a href='#q11' style={{textDecoration:"underline",color:"#5717B4"}}>11. HTML5 is not supported on my device, can I still run the
            Broadband Performance Tester?</a>
            <br/>
            <a href='#q12' style={{textDecoration:"underline",color:"#5717B4"}}>12. Do I have to make any changes
            to my Browser settings to use the Broadband Performance tester?</a>
              <br />
              <br />
              ----------------------------------------------------------------------------------------------------------------------------------------------------
            </p>
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}
              id='q1'
            >
              Q1. What does the Broadband Performance Tester do?
            </h1>
            
            <p style={{ color: "#5717B4", marginLeft: 20, fontSize: 15,marginRight:10, fontWeight:'bold' }}>
              A1. It enables you to check the throughput performance (speed) of your broadband service. 
              The results can help your Broadband Provider to determine if you have a potential problem with your service. 
              There are many factors that influence broadband performance and speed. 
              The BT Speed Tester can be used to indicate whether your broadband service is performing as expected. 
              If you are having problems at specific times of day it’s a good idea to run speed tests at those times, 
              as this will help to provide a more accurate view of any potential issue. 
            </p>
            
            <br />
                      <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}
              id='q2'
            >
            Q2. What tests does the Broadband Performance Tester complete?
            </h1>
            
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
            A2. The tests completed will depend on the service you purchase from your Broadband Provider and the features they consume from BT Wholesale. The initial basic test sequence includes a download, upload and latency check and can be run as many times as you require. It uses a HTML5 based application to send and receive data to and from your device e.g. PC, laptop, tablet, smartphone etc. using standard internet protocols TCP/IP.<br/>
Once the basic test has completed, and if you think you may have a performance problem, you can choose to complete an <span style={{color:"black"}}>‘Additional Diagnostic’</span> test, this compares your results from the initial tests against service level data in the BT Wholesale systems. The tool determines if your throughput is within the limits of the product service levels that BT Wholesale provides to your broadband Provider. The results are presented back to you and should you wish to discuss them, you will need to contact your Service Provider. The ‘Additional Diagnostic’ test result is stored and can be used to support further diagnostics, if required.

           
            </p>
           
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}
              id='q3'
            >
            Q3. What types of faults or problems can the Broadband Performance
            Tester help me with?
            </h1>
            
            <p style={{ color: "#5717B4", marginLeft: 20, marginRight:10,fontSize: 15, fontWeight:'bold' }}>
            A3. The Broadband Performance Tester has been designed to help you with throughput performance related problems (may also be known as speed related problems).<br/>
            This is where your service has a working connection, but appears to running slower than expected, for example; your downloads are taking far too long, your internet service keeps stopping, the on demand video services keeps stopping and starting or online gaming is too slow etc.<br/>
            Sometimes performance related issues are not caused by your physical broadband connection, but may be a result of recent changes to your service, such as interference from other electrical devices, how you connect to your router/modem/hub i.e. either Wi-Fi or cable, multiple devices using the service at the same time, or lots of people trying to access the same website/piece of content, etc. 
            
            </p>
            
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}
              id='q4'
            >
            Q4. What are the pre-requisites for using the Broadband Performance
            Tester?
            </h1>
           
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
            A4. Before you can use the Broadband Performance Tester, we recommend that you check:<br/>
            <br />
            1. Your broadband service is supplied to your broadband provider by BT Wholesale. 
            <br />
            2. Your broadband modem or router must be ‘In Sync’. This means that the indicator lights on your modem/router/hub (the equipment that connects your device to the broadband enabled line) are showing the correct status for an ‘In Sync’ service (Check your handbook or ‘Help App’) and that you can browse to a known working website, e.g. www.google.co.uk. 
            <br />
            3. Your device must use an Internet Browser that is HTML 5
            compatible.
            <br />
            4. <span style={{textDecoration:"underline",fontSize:16}}>Your device is connected to the broadband line to be tested.</span> The Broadband Performance Tester might not work on certain devices e.g. iPads/Tablets, smartphones or other mobile devices, depending on Browser compatibility.
            <br />
            5. For best results ensure that your device is directly connected to your modem/router/hub via an Ethernet (wired) connection i.e. not connected via wireless/Wi-Fi or a power-line adapter. 
            <br />
            6. If you are using an Ethernet connection, any wireless/Wi-Fi adapter in your computer is switched off or disabled. 
            <br />
            7. Close any other programs that may be running on your computer, including any background programs such as a corporate VPN or Peer to Peer (P2P) clients etc. 
            <br />
            8. We recommend rebooting your modem/router/hub by powering it off, waiting one minute, and then powering it up again. Wait for any lights on the router to stabilise before starting the test.
            <br />
            9. Restart your browser and clear its cache.
            <br />
            10. Ensure no other people or devices (e.g. broadband-connected TV set-top boxes) are using the broadband line during the test. 
            <br />
            <br />
            Please note, it can take up to 10 days for your broadband line to
            settle at its most stable speed. Bear in mind that any speed test during
            this period may not reflect the true long-term speed of your
            broadband service.
            
            </p>
           
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q5'
            >
            Q5. What do the terms shown in the test result screens mean?
            </h1>
            
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
            A5. Explanation of results:<br/><br/>
            <span style={{ color:"black"}}>Basic Test Results screen</span><br/><br/>
            <span style={{ color:"black"}}> > Download Speed (Mbps) - </span>
            This is the actual download throughput speed achieved during the performance test and is measured in mega-bits per second (Mbps). This shows how fast your connection was able to download a file from the test server onto your device.<br/>
            <span style={{ color:"black"}}> > Upload Speed (Mbps) - </span>
            This is the actual upload throughput speed achieved during the performance test and is measured in mega-bits per second (Mbps). This shows how fast your connection was able to upload a file from your device to the test server.<br/>
            <span style={{ color:"black"}}> > Latency (ms) - </span>This is a measure of
            how long it takes to request a data item from a remote location. In
            this test, your device requests a file from the speed test server
            using HTTP and the delay is calculated on how long it takes for the
            file to arrive on your device. The time delay is measured in
            milliseconds.
            <br />
            <br />
            <span style={{ color:"black"}}>Further Diagnostic Results screen</span><br/>
            (Please note: You may not see all
            of the results listed below on your test results. The actual
            parameters shown depend on the broadband service provided).
            <br/>
            <br/>
            <span style={{ color:"black"}}> > Acceptable range of speeds (Mbps) - </span>This is the speed range that we believe is acceptable for your broadband circuit and is measured in Mega-bits per second (Mbps). If your download throughput speed lies in this range there is no fault on your line and your broadband service is deemed to be working OK. <br/>
            <span style={{ color:"black"}}> > Download Speed (Mbps) - </span>This is the actual download throughput speed achieved during the performance test and is measured in mega-bits per second (Mbps). This shows how fast your connection was able to download a file from the test server onto your device. <br/>
            <span style={{ color:"black"}}> > DSL Downstream connection rate (Mbps) - </span> This is the downstream broadband synchronisation rate (also known as line rate). Your broadband modem/router needs to synchronise with the broadband equipment within your local telephone exchange, or street cabinet. When the equipment synchronises it agrees on a maximum rate that data can be transferred between them. This line rate (or synchronisation rate) will always be higher than the actual throughput speed you can achieve when downloading/receiving data.<br/>
            <span style={{ color:"black"}}> > DSL Upstream connection rate (Mbps) - </span> This is the upstream broadband synchronisation rate (also known as line rate). Your broadband modem/router needs to synchronise with the broadband equipment within your local telephone exchange or street cabinet. When the equipment synchronises it agrees on a maximum rate that data can be transferred between them. This line rate (or synchronisation rate) will always be higher than the actual throughput speed you can achieve when uploading/sending data.<br/>
            <span style={{ color:"black"}}> > IP profile rate (Mbps) - </span>To ensure your line doesn’t receive more data than it can manage, we set a parameter within our network called IP Profile. This profile will be slightly less than the DSL connection rate negotiated by your router. If this wasn’t set we may try to transmit more data to you than your broadband circuit can manage and this would end up having a negative effect on your broadband speed.<br/>
            <span style={{ color:"black"}}> > Maximum Achievable Speed (Mbps) – </span>This is the theoretical maximum speed that your Broadband Service could achieve. A number of factors will affect this including the physical length of your line, broadband product in use, type of connection between your device and the Broadband modem/router/hub, other users on your Broadband service, other programs or applications running on the device.<br/>
            <span style={{ color:"black"}}> > Upload Speed (Mbps) - </span>This is the actual upload throughput speed achieved during the performance test and is measured in mega-bits per second (Mbps). This shows how fast your connection was able to upload a file from your device to the test server.
           
            </p>
           
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q6'
            >
            Q6. What is a Broadband Service ID or BBEU?
            </h1>
           
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
            A6. A Broadband Service ID  or BBEU is a unique identifier for your Broadband service. Your Broadband provider should give this to you.
            </p>
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q7'
            >
            Q7. Do I always need to enter a Broadband Service ID?
            </h1>
            
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
            A7. You can use the Telephone Number (directory number or DN) for traditional Broadband services provided over copper including ADSL, FTTC VDSL & G.fast. If you have problems using the Telephone Number please contact your Broadband provider to request your Broadband Service ID.
            </p>
            
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q8'
            >
            Q8. What is an Access line ID or ALID?
            </h1>
           
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
            A8. This is a unique identifier for Single order Broadband products. Your Broadband provider should give this to you.</p>
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q9'
            >
            Q9. Which identifier should I to use – Broadband Service ID, Access Line ID or Telephone Number?
            </h1>
           
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
            A9. This depends on the Broadband service you have. You can use the Broadband Service ID for all broadband services. An Access Line ID (ALID) can be used for Single order products. The telephone number can be used for traditional Broadband delivered over copper (ADSL, FTTC VDSL & G.fast). <br/>
            You only need to use one identifier. If you’re not sure what Broadband service you have or don’t know your Broadband Service ID  or Access Line ID, please contact your Broadband provider.

            </p>
            <br />
            <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q10'
            >
            Q10. What should I do if I have difficulty running the Broadband
            Performance Tester?
            </h1> 
            <p style={{ color: "#5717B4", marginLeft: 20, fontSize: 15,marginRight:10, fontWeight:'bold' }}>A10. If you have any difficulties in running the tester then please contact your Broadband Provider. 
           
            </p>
            <br />
             <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q11'
            >
            Q11. HTML5 is not supported on my device, can I still run the
            Broadband Performance Tester?
            </h1>
            <p style={{ color: "#5717B4", marginLeft: 20, marginRight:10,fontSize: 15, fontWeight:'bold' }}>
             A11. No, your device must have a browser or application that supports HTML5 to run the test. 
           
            </p>
            <br />
             <h1
              style={{
                color: "#FF90FF",
                marginLeft: 20,
                fontSize: 20,
                fontWeight: "bold"
              }}id='q12'
            >
              Q12. Do I have to make any changes
            to my Browser settings to use the Broadband Performance tester?
            </h1>
            <p style={{ color: "#5717B4", marginLeft: 20,marginRight:10, fontSize: 15, fontWeight:'bold' }}>
             A12. Internet Explorer settings:<br/>
From the ‘Tools’ menu check your ‘Compatibility View Settings’; Un-check the ‘Display Intranet sites and compatibility view’ & ‘Use Microsoft Compatibility lists’ options. 

           
            </p>
            <br/>
          </div>
          <div className="col-1"></div>
        </div>
      
    );
  }
}

export default withRouter(SpeedTestGuidance);
