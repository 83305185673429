import React, { Component } from "react";
import logo from "../../Assets/logo.png";
import Box from "../../Components/Box";
import InitiateFurtherDiagnostics from "../InitiatFurtherDiagnostics";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { polyfill } from "es6-promise";
import axios from "axios";

import completeImage from "../../Assets/checked.png";
import downloadIcon from "../../Assets/downloadIcon.png";
import initialImage from "../../Assets/initialImage.gif";
import pingIcon from "../../Assets/pingIcon.png";
import query from "query-string";
import uploadIcon from "../../Assets/uploadIcon.png";
import { withRouter } from "react-router";
import intermediateImage from "../../Assets/intermediate.png";
import { Modal } from "react-bootstrap";
import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import useScript from 'usescript-hook';
 //var test_start;

//import "react-app-polyfill/stable"; //Nikita
//import "react-app-polyfill/ie11"; //

polyfill();

var sTime;
var arrUpData1 = [];
var Download_complete = 0;
var downspeed = 0,
  upspeed = 0,
  bytes = 0,
  start = 0,
  end = 0,
  startUpTime = 0,
  endUpTime = 0,
  uploadSize = 0;
var max_UP_speed = 0;
var startTime, endTime, myid;
var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
myid =
  "?id=" + myid + possible.charAt(Math.floor(Math.random() * possible.length));
var downloadSize = [62914560];
var uploadSize = [450877];
var processing = 0;
var prevLoaded = 0;
var count_download = 0;
var bitsLoaded = 0;
var interval = null;
var graceTimeDone = false;
var time_dl = 15;
var download = [];
var dlProgress = null;
var time_dlGraceTime = 1.5;
var elem = null;
var elem1 = null;
var arr = [];
var i = 0;

var count_Upload = 0;
var arrUpTime = [];
var arrUpData = [];
var arrUpData1 = [];
var upsize = 0;

var upcnt = 0;
var myMap = new Map();
//var arrUpData1=[] ;
var arrUpSpeed = [];
var latencyCnt = 0;
var minLatencyValue;
var timeOutValue = 4000;
var speedSlab = 0;
var data = 61275;
var myVar;
var myData;
var incData = 0;
var tempData = 0;
var InitialuploadSpeed;

var urlupload = process.env.REACT_APP_UPLOAD_URL;
var latencyURL = process.env.REACT_APP_PING_URL;
var downloadURL = process.env.REACT_APP_DOWNLOAD_URL;
var cnt = 1;

var flag = 0;

var arrLatency = [];

var numcnt;

var max_speed = 0;
var latencyResut = 0;
// Create a ES6 class component  10

var sTime;
var downloadedDataInCall1 = 0;
var downloadedDataInCall2 = 0;
var downloadedDataInCall3 = 0;
var downloadedDataInCall4 = 0;
var dataDownloaded = 0;
var startTimeCaptured = 0;
var startTime = 0;

class SpeedTestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      box: [
        { name: "PING", img: pingIcon, value: "--", unit: "ms" },
        { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
        { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
      ],

      ShowFurtherDiagnosticsComponent: false,
      dn: 0,
      dataList: [],
      timeList: [],
      error: null,
      results: [],
      initialMessage: "",
      showFurtherDiagnostics: false,
      show: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        If you're not getting the speed test result you were expecting you can
        run an Additional Diagnostic test by clicking the button to the left.
      </Tooltip>
    );
  };
  componentWillMount() {
    axios.get(latencyURL + "?_=" + new Date().getTime());
    this.setState({
      initialImage: intermediateImage,
    });
  }

  async componentDidMount() {
    //console.log(process.env.REACT_APP_UPLOAD_URL)
    const search = query.parse(this.props.location.search);
    if (Object.keys(search) && Object.keys(search).length > 0) {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: search.ping || "--",
            unit: "ms",
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: search.download || "--",
            unit: "Mbps",
          },
          {
            name: "UPLOAD",
            img: uploadIcon,
            value: search.upload || "--",
            unit: "Mbps",
          },
        ],
        bytes: parseInt(search.bytes || "0"),
        end: parseInt(search.end || "0"),
        endUpTime: parseInt(search.endUpTime || "0"),
        start: parseInt(search.start || "0"),
        startUpTime: parseInt(search.startUpTime || "0"),
        uploadSize: parseInt(search.uploadSize || "0"),
        ShowFurtherDiagnosticsComponent: false,
        showFurtherDiagnostics: true,
        initialImage: completeImage,
        showExternalHTML: { __html: '<div class="ext">Hello!</div>' },
      });
    } else {
      await axios.get(latencyURL + "?_=" + new Date().getTime());

      await setTimeout(() => console.log("hi"), 2000);

      await this.delay();
      await this.latency();

      this.setState({
        initialImage: intermediateImage,
      });
      await this.delay();
      await this.calculateDownloadSpeed();
      this.setState({
        initialImage: intermediateImage,
      });
    }
  }
  delay = () => {
    axios.get(latencyURL + "?_=" + new Date().getTime());
  };

  test_upload = () => {
    this.calculateData(data);
    myVar = setTimeout(this.alertFunc, 5000);
    this.start_upload_new("id" + cnt);
  };

  calculateData = (dataSizeInBytes) => {
    myData = "d="; // the raw data you will send
    var possible =
      "ABCDEFGHIJRSTUVW6789XYZabcdefghij012345klmnopqrstuvwxyzKLMNOPQ";
    for (
      var i = 0;
      i < dataSizeInBytes;
      i++ //if you want to send 1 kb (2 + 1022 bytes = 1024b = 1kb). change it the way you want
    ) {
      myData += possible.charAt(Math.floor(Math.random() * possible.length)); // add one byte of data;
    }

    incData = myData;
  };

  start_upload_new = (getUpId) => {
    //console.log("start_upload_new");
    this.calculateDataTemp(10000);

    incData = tempData + new Date().getTime() + incData;

    //console.log(incData.length);
    ////console.log("data==============================>  ", incData);

    if (flag == 0) {
      this.setState({
        initialImage: initialImage,
      });
      var sTime, eTime;

      sTime = new Date().getTime();
      var config = {
        cache: false,
        timeout: 5000,
      };

      return axios
        .post(urlupload + Math.random(), incData, config)
        .then((res) => {
          //console.log(res);
          eTime = new Date().getTime();
          var bitsLoaded1 = incData.length;
          //console.log("bitsLoaded++++++>", bitsLoaded);
          var minusSeconds = eTime - sTime;
          var json = JSON.parse(JSON.stringify(res));
          //console.log("json", json);
          //console.log("uploadSizeBytes " + json.uploadSizeBytes);
          var a = res.data.uploadSpeedPerSecHumanReadable;
          var speedUPMbps_s = a.toFixed(2);
          //console.log("speedUPMbps_s " + speedUPMbps_s);
          var bitsLoaded1 = parseFloat(bitsLoaded1) * 8;
          var MiliSecTOSec = minusSeconds / 1000;
          var speedUPBps = bitsLoaded1 / MiliSecTOSec;
          var speedUPKbps = speedUPBps / 1000;
          var speedUPMbps = (speedUPKbps / 1000).toFixed(2);
          arrUpData1.push({
            id: getUpId,
            startTime: sTime,
            endTime: eTime,
            duration: minusSeconds,
            dataDownloaded: bitsLoaded1,
            ServerSpeed: speedUPMbps_s,
          });
          cnt++;
          this.start_upload_new("id" + cnt);
          //console.log( "this.start_upload_new(+cnt)=================>","id" + cnt);
          if (flag == 0) {
            this.setState({
              box: [
                {
                  name: "PING",
                  img: pingIcon,
                  value: this.state.box[0].value,
                  unit: "ms",
                },
                {
                  name: "DOWNLOAD",
                  img: downloadIcon,
                  value: this.state.box[1].value,
                  unit: "Mbps",
                },
                { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
              ],
              value1: speedUPMbps_s,
              value2: this.state.box[2].name,
              value3: this.state.box[2].unit,
            });
          }
        })
        .catch((error) => {
          this.setState({
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms",
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: this.state.box[1].value,
                unit: "Mbps",
              },
              { name: "UPLOAD", img: uploadIcon, value: 0, unit: "Mbps" },
            ],
            value1: 0,
            value2: this.state.box[2].name,
            value3: this.state.box[2].unit,
            startUpTime: startUpTime,
            endUpTime: endUpTime,
            uploadSize: uploadSize,
            initialImage: completeImage,
            initialMessage: "Your Diagnostics Results are here",
            showFurtherDiagnostics: true,
          });
        });
    }
  };

  calculateDataTemp = (dataSizeInBytes) => {
    tempData = "d="; // the raw data you will send
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (
      var i = 0;
      i < dataSizeInBytes;
      i++ //if you want to send 1 kb (2 + 1022 bytes = 1024b = 1kb). change it the way you want
    ) {
      tempData += possible.charAt(Math.floor(Math.random() * possible.length)); // add one byte of data;
    }
  };

  GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  alertFunc = () => {
    flag = 1;

    //console.log("arrUpData1 length initial  == " + arrUpData1.length);
    //console.log("arrUpData1  initial  == " + arrUpData1);

    if (arrUpData1.length > 0) {
      for (var item in arrUpData1) {
        //									//console.log(" arrUpData1 =" + arrUpData1[item].ServerSpeed );
      }

      //	$("#textshow").hide();

      arrUpData1.sort(this.GetSortOrder("ServerSpeed"));
      //console.log("arrUpData1 length  == " + arrUpData1.length);

      var bottemRem = Math.trunc(arrUpData1.length * (15 / 100));

      //console.log("Removing 15% High   == " + bottemRem);

      /*if(bottemRem < 1 && arrUpData1.length > 1){
             bottemRem = 1;
             }
             */
      ////console.log('Removing 5% High new  == ' + bottemRem);

      var TopRem = Math.trunc(arrUpData1.length * (50 / 100));
      //console.log("Removing 50% Low  == " + TopRem);

      arrUpData1.length = arrUpData1.length - bottemRem;
      //console.log("After Removing 15% High  == " + arrUpData1.length);

      arrUpData1.splice(0, TopRem);
      //console.log("After Removing 50% Low  == " + arrUpData1.length);

      var sumupSpeed = 0;

      var sum = 0;
      for (var item in arrUpData1) {
        sumupSpeed += parseFloat(arrUpData1[item].ServerSpeed, 10);
        sum = sum + arrUpData1[item].dataDownloaded;
      }

      var avgSpeed = (sumupSpeed / arrUpData1.length).toFixed(2);

      //console.log("Upload speed is = " + avgSpeed);

      max_UP_speed = avgSpeed;
      if (max_UP_speed === undefined || isNaN(max_UP_speed)) {
        max_UP_speed = 0.0;
      }

      if (sum === undefined || isNaN(sum)) {
        uploadSize = myData.length;
      } else {
        uploadSize = ~~sum;
      }

      //console.log("Max Speed == " + max_UP_speed);
      upspeed = max_UP_speed;

      var updCnt = parseInt(arrUpData1.length);
      startUpTime = arrUpData1[0].startTime;
      endUpTime = arrUpData1[0].endTime;
      //console.log("Final upspeed is = " + upspeed);
      var maxSP = "YOUR UPLOAD SPEED IS :" + upspeed + " Mbps";
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms",
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: this.state.box[1].value,
            unit: "Mbps",
          },
          { name: "UPLOAD", img: uploadIcon, value: upspeed, unit: "Mbps" },
        ],
        value1: upspeed,
        value2: this.state.box[2].name,
        value3: this.state.box[2].unit,
        startUpTime: startUpTime,
        endUpTime: endUpTime,
        uploadSize: uploadSize,
        initialImage: completeImage,
        initialMessage: "Your Diagnostics Results are here",
        showFurtherDiagnostics: true,
      });

      if (upspeed != 0.0) {
        var endUpTimeVar = (uploadSize * 8) / upspeed + startUpTime;
        endUpTime = parseInt(endUpTimeVar);
      }

      if (downspeed != 0.0) {
        var endVar = (bytes * 8) / downspeed + start;
        end = parseInt(endVar);
      }

      //console.log("Get Speed Method");
    } else {
      //console.log("Get Speed Method");
    }

    //;
  };

  async latency() {
    var letenctStartTime1 = new Date().getTime();
    this.setState({
      initialImage: initialImage,
    });
    //console.log("start latency");
    var arrLatency = [];
    await axios({
      method: "get",
      url: latencyURL + "?_=" + new Date().getTime(),
      timeout: 5000,
      cache: false,
    })
      .then((data) => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime1);

        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency1----------->", avgLatency);
        var latencyResut1 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut1----------->", latencyResut1);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut1, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: latencyResut1,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      })
      .catch((error) => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      });

    var letenctStartTime2 = new Date().getTime();
    axios({
      method: "get",
      url: latencyURL + "?_=" + new Date().getTime(),
      timeout: 5000,
      cache: false,
    })
      .then((data) => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime2);

        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency2----------->", avgLatency);
        var latencyResut2 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut2----------->", latencyResut2);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut2, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: latencyResut2,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      })
      .catch((error) => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      });

    var letenctStartTime3 = new Date().getTime();
    axios({
      method: "get",
      url: latencyURL + "?_=" + new Date().getTime(),
      timeout: 5000,
      cache: false,
    })
      .then((data) => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime3);

        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency3----------->", avgLatency);
        var latencyResut3 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut3----------->", latencyResut3);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut3, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: latencyResut3,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      })
      .catch((error) => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      });

    var letenctStartTime4 = new Date().getTime();
    axios({
      method: "get",
      url: latencyURL + "?_=" + new Date().getTime(),
      timeout: 5000,
      cache: false,
    })
      .then((data) => {
        var letenctEndTime = new Date().getTime();
        var LatencyResuttmp = parseInt(letenctEndTime - letenctStartTime4);

        //console.log();
        arrLatency.push(LatencyResuttmp);
        //console.log("latency array--------------->", arrLatency);

        var avgLatency = Math.min.apply(Math, arrLatency);
        //console.log("avgLatency4----------->", avgLatency);
        var latencyResut4 = Math.round(avgLatency * 0.75);
        //console.log("latencyResut4----------->", latencyResut4);

        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: latencyResut4, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: latencyResut4,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      })
      .catch((error) => {
        this.setState({
          box: [
            { name: "PING", img: pingIcon, value: 0, unit: "ms" },
            { name: "DOWNLOAD", img: downloadIcon, value: "--", unit: "Mbps" },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: 0,
          value2: this.state.box[0].name,
          value3: this.state.box[0].unit,
        });
      });
  }

  async calculateDownloadSpeed() {
    this.setState({
      initialImage: initialImage,
    });
    var maxSpeed = 0;
    var speeda = [];
    var speedEach = 0;
    var sumData = 0;
    var sumTime = 0;
    var maxSpeedArray = [];
    var downloadSpeed = 0;
    sTime = new Date().getTime();
    console.log("Start Time:->" + sTime);

    //Nikita: start

    // Safari 3.0+ "[object HTMLElementConstructor]"
    /*var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );
    console.log("isSafari:->" + isSafari);
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@ false || !!document.documentMode;
    console.log("isIE:->" + isIE);
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    console.log("isEdge:->" + isEdge);

    if (!isEdge) {
      console.log("Inside If as isSafari detected");
    console.log("before test_start");
      var speed =  await test_start();
      this.delay();
      console.log("received speed: "+speed);
      console.log("after test_start");
      this.setState({
        dataList: [],
        sp: speed,
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms",
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: speed,
            unit: "Mbps",
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
        ],
        value1: speed,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit,
        bytes: 10000,
        start: 1000000,
        end: 1000000,
      });
      await this.delay();
      this.test_upload();
    }
     else {*/
    //Nikita: end
     
    await axios
      .all([this.getData1(), this.getData2(), this.getData3(), this.getData4()])
      .then((data) => {
        console.log("Start Data:->" + data[0]);
        if (startTimeCaptured >= 15) {
          var eTime = new Date().getTime();
          var maxSpeedArray = [];
          if (typeof this.state.sp1 !== "undefined") {
            maxSpeedArray.push(this.state.sp1);
          }
          if (typeof this.state.sp2 !== "undefined") {
            maxSpeedArray.push(this.state.sp2);
          }
          if (typeof this.state.sp3 !== "undefined") {
            maxSpeedArray.push(this.state.sp3);
          }
          if (typeof this.state.sp4 !== "undefined") {
            maxSpeedArray.push(this.state.sp4);
          }
          var speedMax = Math.max(...maxSpeedArray);
          if (typeof this.state.dt1 !== "undefined") {
            sumData = sumData + this.state.dt1;
          }
          if (typeof this.state.dt2 !== "undefined") {
            sumData = sumData + this.state.dt2;
          }
          if (typeof this.state.dt3 !== "undefined") {
            sumData = sumData + this.state.dt3;
          }
          if (typeof this.state.dt4 !== "undefined") {
            sumData = sumData + this.state.dt4;
          }

          var duration = eTime - startTime;
          //var duration = 3000;
          speedEach = ((sumData * 8) / 1000000 / (duration / 1000)).toFixed(2);
          //console.log("Duration is :->", duration);
          //console.log("SumData is :->", sumData);
          var speed = speedEach;
        
          this.setState({
            dataList: [],
            sp: speed,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms",
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: speed,
                unit: "Mbps",
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit,
            bytes: sumData,
            start: sTime,
            end: eTime,
          });
        }
      })
      .catch((error) => {
        var eTime = new Date().getTime();
        var maxSpeedArray = [];
        if (typeof this.state.sp1 !== "undefined") {
          maxSpeedArray.push(this.state.sp1);
        }
        if (typeof this.state.sp2 !== "undefined") {
          maxSpeedArray.push(this.state.sp2);
        }
        if (typeof this.state.sp3 !== "undefined") {
          maxSpeedArray.push(this.state.sp3);
        }
        if (typeof this.state.sp4 !== "undefined") {
          maxSpeedArray.push(this.state.sp4);
        }
        var speedMax = Math.max(...maxSpeedArray);
        if (typeof this.state.dt1 !== "undefined") {
          sumData = sumData + this.state.dt1;
        }
        if (typeof this.state.dt2 !== "undefined") {
          sumData = sumData + this.state.dt2;
        }
        if (typeof this.state.dt3 !== "undefined") {
          sumData = sumData + this.state.dt3;
        }
        if (typeof this.state.dt4 !== "undefined") {
          sumData = sumData + this.state.dt4;
        }

        var duration = eTime - startTime;
        
        speedEach = ((sumData * 8) / 1000000 / (duration / 1000)).toFixed(2);
        
        var speed = speedEach;

        this.setState({
          dataList: [],
          sp: speed,
          box: [
            {
              name: "PING",
              img: pingIcon,
              value: this.state.box[0].value,
              unit: "ms",
            },
            {
              name: "DOWNLOAD",
              img: downloadIcon,
              value: 0,
              unit: "Mbps",
            },
            { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
          ],
          value1: 0,
          value2: this.state.box[1].name,
          value3: this.state.box[1].unit,
          bytes: sumData,
          start: sTime,
          end: eTime,
        });
      });
    await this.delay();
    this.test_upload();
  }
  
  getData1() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      url: downloadURL + "?_=" + new Date().getTime()+ "&_Thread1",
      // url:  latencyURL,
      timeout: 5000,
      cache: false,

      onDownloadProgress: (progressEvent) => {
        var eTime = new Date().getTime();

        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall1 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall1 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall1 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData1:->" + speed);
          speed2Array.push(speed);
          var speedArrayMax = Math.max(...speed2Array);
          dataListArray.push(downloadedDataInCall1);

          var data = Math.max(...dataListArray);
          //				//console.log("timealist getdata :",timeListArray);
          this.setState({
            dataList1: dataListArray,
            dt1: data,
            sp1: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms",
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps",
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit,
          });
        }
      },
    }).catch((error) => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms",
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps",
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit,
      });
    });
  }

  getData2() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      url: downloadURL + "?_=" + new Date().getTime()+ "&_Thread2",
      timeout: 5000,
      cache: false,

      onDownloadProgress: (progressEvent) => {
        var eTime = new Date().getTime();
        //console.log("EndTime getData2:->" + eTime);
        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall2 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall2 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall2 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData2:->" + speed);
          speed2Array.push(speed);
          var speedArrayMax = Math.max(...speed2Array);

          dataListArray.push(downloadedDataInCall2);

          var data = Math.max(...dataListArray);
          this.setState({
            dataList2: dataListArray,
            dt2: data,
            sp2: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms",
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps",
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit,
          });
        }
      },
    }).catch((error) => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms",
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps",
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit,
      });
    });
  }
  getData3() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      url: downloadURL + "?_=" + new Date().getTime()+ "&_Thread3",
      timeout: 5000,
      cache: false,

      onDownloadProgress: (progressEvent) => {
        var eTime = new Date().getTime();
        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall3 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall3 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall3 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData3:->" + speed);

          speed2Array.push(speed);
          var speedArrayMax = Math.max(...speed2Array);

          dataListArray.push(downloadedDataInCall3);

          var data = Math.max(...dataListArray);
          //				//console.log("timealist getdata :",timeListArray);
          this.setState({
            dataList3: dataListArray,
            dt3: data,
            sp3: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms",
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps",
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit,
          });
        }
      },
    }).catch((error) => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms",
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps",
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit,
      });
    });
  }
  getData4() {
    var dataListArray = [];
    var speed2Array = [];

    return axios({
      method: "get",
      url: downloadURL + "?_=" + new Date().getTime()+ "&_Thread4",
      timeout: 5000,
      cache: false,

      onDownloadProgress: (progressEvent) => {
        var eTime = new Date().getTime();
        if (startTimeCaptured == 0) {
          startTime = eTime;
          startTimeCaptured = 1;
          downloadedDataInCall4 = progressEvent.loaded;
          //console.log("DownloadStartTime:->", startTime);
        } else if (startTimeCaptured < 15) {
          downloadedDataInCall4 = progressEvent.loaded;
          startTimeCaptured = startTimeCaptured + 1;
        } else {
          downloadedDataInCall4 = progressEvent.loaded;
          var duration = eTime - startTime;
          var downloadedData =
            downloadedDataInCall1 +
            downloadedDataInCall2 +
            downloadedDataInCall3 +
            downloadedDataInCall4;
          var speed = (
            (downloadedData * 8) /
            1000000 /
            (duration / 1000)
          ).toFixed(2);
          //console.log("speed getData4:->" + speed);
          speed2Array.push(speed);

          var speedArrayMax = Math.max(...speed2Array);

          dataListArray.push(downloadedDataInCall4);

          var data = Math.max(...dataListArray);
          //				//console.log("timealist getdata :",timeListArray);
          this.setState({
            dataList4: dataListArray,
            dt4: data,
            sp4: speedArrayMax,
            box: [
              {
                name: "PING",
                img: pingIcon,
                value: this.state.box[0].value,
                unit: "ms",
              },
              {
                name: "DOWNLOAD",
                img: downloadIcon,
                value: "--",
                unit: "Mbps",
              },
              { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
            ],
            value1: speed,
            value2: this.state.box[1].name,
            value3: this.state.box[1].unit,
          });
        }
      },
    }).catch((error) => {
      this.setState({
        box: [
          {
            name: "PING",
            img: pingIcon,
            value: this.state.box[0].value,
            unit: "ms",
          },
          {
            name: "DOWNLOAD",
            img: downloadIcon,
            value: 0,
            unit: "Mbps",
          },
          { name: "UPLOAD", img: uploadIcon, value: "--", unit: "Mbps" },
        ],
        value1: 0,
        value2: this.state.box[1].name,
        value3: this.state.box[1].unit,
      });
    });
  }


  handleClose() {
    this.setState((state) => ({
      show: !state.show,
    }));
  }

  
    
    
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-3 col"></div>
        <div
          className="col-md-10 col-sm-12 col-xs-12 card space-for-header space-for-ie full-width"
          style={{ background: "#5013AB", color: "white" }}
        >
          <div className="card-body">
            <div className="row">
              {/* full rocket section */}
              <div className="col-md-4 col-sm-12 " style={{}}>
                <div className="row">
                  <div className="col-sm-12">
                    <h6>Basic Speed Test</h6>
                  </div>
                  <div className="col-6 col-sm-6 col-md-12">
                    <img
                      className="smallScreen bigScreen ipad-css"
                      src={this.state.initialImage}
                      title={this.state.initialMessage}
                    />
                  </div>
                  <div className="col-6 col-md-12">
                    <div
                      className={`col-sm-12 ${
                        this.state.showFurtherDiagnostics && "green"
                      }`}
                    >
                      {this.state.showFurtherDiagnostics === false ? (
                        <div className="value-css value-csss value-ipad">
                          {this.state.value1} {this.state.value3}{" "}
                        </div>
                      ) : (
                        <div className="successTestInitial successTest">
                          <span className="spanSuccess-css-bb">Broadband</span>{" "}
                          <span className="spanSuccess-css-sp"> Speed</span>{" "}
                          <span className="spanSuccess-css-test"> Test</span>
                        </div>
                      )}
                    </div>
                    <div
                      className={`col-sm-12 ${
                        this.state.showFurtherDiagnostics && "green"
                      }`}
                    >
                      {this.state.showFurtherDiagnostics === false ? (
                        <h6
                          style={{
                            marginLeft: 65,
                            fontWeight: "bold",
                            color: "#FF90FF",
                          }}
                        ></h6>
                      ) : (
                        <div className="successTestInit successTestAfter ">
                          <span className="spanSuccess-css-ex">Executed</span>{" "}
                          <span className="spanSuccess-css-su">
                            {" "}
                            Successfully
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* feed boxes */}
              <div className="col-md-8 col-sm-12">
                <div className="row">
                  <Box
                    name={this.state.box[0].name}
                    value={this.state.box[0].value}
                    unit={this.state.box[0].unit}
                    img={this.state.box[0].img}
                    className="col-md-4 col-sm-6 col-12"
                  />
                  <Box
                    name={this.state.box[1].name}
                    value={this.state.box[1].value}
                    unit={this.state.box[1].unit}
                    img={this.state.box[1].img}
                    className="col-md-4 col-sm-6 col-12"
                  />
                  <Box
                    name={this.state.box[2].name}
                    img={this.state.box[2].img}
                    value={this.state.box[2].value}
                    unit={this.state.box[2].unit}
                    className="col-md-4 col-sm-6 col-12"
                  />
                </div>
                {this.state.showFurtherDiagnostics === true ? (
                  <div className="" style={{ display: "flex", marginLeft: 0 }}>
                    <div
                      className="col-xs-4"
                      style={{ position: "relative", marginRight: "-5px" }}
                    >
                      {this.props.location.search &&
                      Object.keys(query.parse(this.props.location.search))
                        .length > 0 ? (
                        <a href="/details">
                          <button className="circle">AGAIN</button>
                        </a>
                      ) : (
                        <button
                          className="circle"
                          onClick={() => window.location.reload(true)}
                        >
                          AGAIN
                        </button>
                      )}
                    </div>
                    <div
                      className="col-xs-1 line"
                      style={{ position: "relative", marginRight: "9px" }}
                    ></div>
                    <div className="col-xs-6 ">
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td>
                              <Link
                                to={`/initiateFurtherDiag?ping=${this.state.box[0].value}&download=${this.state.box[1].value}&upload=${this.state.box[2].value}&bytes=${this.state.bytes}&start=${this.state.start}&end=${this.state.end}&startUpTime=${this.state.startUpTime}&endUpTime=${this.state.endUpTime}&uploadSize=${this.state.uploadSize}`}
                              >
                                <button
                                  className="rectangle"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                  }}
                                >
                                  Additional Diagnostic
                                </button>
                              </Link>
                            </td>
                            <td
                              style={{
                                width: "20px",
                              }}
                            >
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.renderTooltip}
                              >
                                <button
                                  className="hoverCircle"
                                  style={{
                                    marginTop: "-10px",
                                    padding: "0px",
                                    width: "20px",
                                  }}
                                >
                                  ?
                                </button>
                              </OverlayTrigger>
                            </td>
                            <td
                              style={{
                                padding: "20px",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  marginRight: "10px",
                                  marginTop: "23px",
                                  fontSize: "12px",
                                }}
                              >
                                If you are testing 500Mb/1000Mb product speeds
                                please{" "}
                                <button
                                  className="clickHereBtn"
                                  onClick={this.handleClose}
                                >
                                  click here
                                </button>
                                .
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="row"></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col"></div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          style={{ maxWidth: "100%" }}
        >
          <div
            style={{ width: "100%", marginTop: "10px", paddingRight: "10px" }}
          >
            <button
              onClick={this.handleClose}
              className="rectangleGuidance"
              style={{
                float: "right",
                fontWeight: "bold",
                textAlign: "end",
                margin: "auto",
              }}
            >
              [CLOSE]
            </button>
            <br />
            <p
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              When testing 500Mb/1000Mb speeds via a computer based device the
              results are dependent on the capability of the device and the
              premises environment.
              <br />
              In addition to the standard guidance provided in the ‘Before You
              Start?’ section you need to ensure that the customers device meets
              the following minimum specification:{" "}
            </p>
            <ol
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              <li>
                Processor should be above or equivalent to Intel® Core™ i5-4570S
                Processor (4 cores, 3.80 max Turbo frequency, 2.90GHz processor
                frequency, 6MB smart cache){" "}
              </li>
              <li>Ensure that at least 4 GB of RAM is free</li>
              <li>1 GB NIC card is recommended</li>
              <li>
                Ethernet cable should have the capability to transfer 1GB data{" "}
              </li>
              <li>Operating System - Windows 8 and above</li>
              <li>
                Minimum browsers supported - Google Chrome Version86, CX's
                Internet Explorer 11 as well as Microsoft Edge 2020 browsers
                version9 (2018 IE is not compatible){" "}
              </li>
              <li>
                Please ensure you are using the latest browser version for
                optimum results.{" "}
              </li>
            </ol>
            <p
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              {" "}
              NB: To ensure accurate results please ensure you are testing via a
              wired connection with all other devices disabled, or switched off
              when running the test.
            </p>
            <br />
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SpeedTestDetails);
