import React, { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import Pdf from "react-to-pdf";
import "./Index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { Modal } from "react-bootstrap";
import { render } from "preact";

const ref = React.createRef();
var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var day = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

var tempDate = new Date();
var date =
  day[tempDate.getDay()] +
  " " +
  tempDate.getDate() +
  " " +
  months[tempDate.getMonth()] +
  " " +
  tempDate.getFullYear() +
  " | " +
  tempDate.getHours() +
  ":" +
  tempDate.getMinutes() +
  ":" +
  tempDate.getSeconds();
var p1 = 0;
var p2 = 0;
var c1 = 0;
var c2 = 0;
var aa = 0;
var bb = 0;
const showProgress = (testSpeed, limitSpeed) => {
  aa = Number(testSpeed);
  bb = Number(limitSpeed);
  console.log(aa, bb);
  if (aa > bb) {
    p1 = 100;
    p2 = Math.round((100 * bb) / aa);
    console.log("hi", p1, p2);
    return (
      <div>
        {console.log("hi 11111111")}
        <div style={{ color: "#28a745", fontSize: 10 }}>{aa} Mbps</div>
        <ProgressBar
          variant="success"
          style={{ borderRadius: 0, marginRight: 10 }}
          now={p1}
        />
        <ProgressBar
          variant="danger"
          style={{ borderRadius: 0, marginRight: 10 }}
          now={p2}
        />
        <div style={{ color: "#dc3545", fontSize: 10 }}>{bb} Mpbs</div>
      </div>
    );
  } else {
    c1 = Math.round((100 * aa) / bb);
    c2 = 100;
    return (
      <div>
        <div style={{ color: "#28a745", fontSize: 10 }}>{aa} Mbps</div>
        <ProgressBar
          variant="success"
          style={{ borderRadius: 0, marginRight: 10 }}
          now={c1}
        />
        <ProgressBar
          variant="danger"
          style={{ borderRadius: 0, marginRight: 10 }}
          now={c2}
        />
        <div style={{ color: "#dc3545", fontSize: 10 }}>{bb} Mbps</div>
      </div>
    );
  }
};

const printDocument = (props) => {
  var tempDate = new Date();
  var date =
    day[tempDate.getDay()] +
    " " +
    tempDate.getDate() +
    " " +
    months[tempDate.getMonth()] +
    " " +
    tempDate.getFullYear() +
    " | " +
    tempDate.getHours() +
    ":" +
    tempDate.getMinutes() +
    ":" +
    tempDate.getSeconds();
  var ping = props.ping;
  var download = props.download;
  var upload = props.upload;
  var ipProfile = props.ipProfile;
  var maxAchievableDownSpeed = props.maxAchievableDownSpeed;
  var lowAcceptableSpeedRange = props.lowAcceptableSpeedRange;
  var highAcceptableSpeedRange = props.highAcceptableSpeedRange;
  var upstreamIPProfileRate = props.upstreamIPProfileRate;
  var upDSLConnectionRate = props.upDSLConnectionRate;
  var downDSLConnectionRate = props.downDSLConnectionRate;
  var resultMsg = props.resultMsg;
  var tapMsg = props.tapMsg;
  var doc = new jsPDF("p", "pt");
  if (props.htmlType == "SIMPLE_BE") {
    console.log(
      props.htmlType,
      upload,
      download,
      ping,
      upstreamIPProfileRate,
      ipProfile,
      upDSLConnectionRate,
      downDSLConnectionRate,
      maxAchievableDownSpeed,
      lowAcceptableSpeedRange,
      tapMsg,
      resultMsg,
      highAcceptableSpeedRange
    );

    doc.setFont("Times New Roman");
    doc.setFontType("normal");
    doc.setFontSize(20);
    doc.setFontStyle("bold");
    doc.setTextColor(255, 0, 0);
    doc.text(60, 80, "Performance Test Results Report");

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.text(60, 140, "Basic Performance Test -");
    var basic_columns = [
      { title: "Time Stamp", dataKey: "timestamp" },
      { title: "Latency", dataKey: "latency" },
      { title: "Download", dataKey: "download" },
      { title: "Upload", dataKey: "upload" },
    ];
    var basic_rows = [
      {
        timestamp: date,
        latency: ping + " Ms",
        download: download + " Mbps",
        upload: upload + " Mbps",
      },
    ];

    doc.autoTable(basic_columns, basic_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      margin: { top: 160, left: 60, right: 60 },
    });

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 240, "Advanced Performance Test -");

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.cell(60, 260, 475, 22, "Download Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      282,
      475,
      22,
      "Download speed achieved during the test was " + download + " Mbps"
    );

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 340, "Summary Advanced Performance Test -");

    var result_columns = [{ title: "Result Message", dataKey: "message" }];
    var result_rows = [{ message: tapMsg }];

    doc.autoTable(result_columns, result_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      startY: 360,
      margin: { top: 360, left: 60, right: 60 },
    });
    // Save the Data
  }
  if (props.htmlType == "SIMPLE_BE_UPSTREAM") {
    console.log(props.htmlType);
    console.log(
      props.htmlType,
      upload,
      download,
      ping,
      upstreamIPProfileRate,
      ipProfile,
      upDSLConnectionRate,
      downDSLConnectionRate,
      maxAchievableDownSpeed,
      lowAcceptableSpeedRange,
      tapMsg,
      resultMsg,
      highAcceptableSpeedRange
    );
    doc.setFont("Times New Roman");
    doc.setFontType("normal");
    doc.setFontSize(20);
    doc.setFontStyle("bold");
    doc.setTextColor(255, 0, 0);
    doc.text(60, 80, "Performance Test Results Report");

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.text(60, 140, "Basic Performance Test -");
    var basic_columns = [
      { title: "Time Stamp", dataKey: "timestamp" },
      { title: "Latency", dataKey: "latency" },
      { title: "Download", dataKey: "download" },
      { title: "Upload", dataKey: "upload" },
    ];
    var basic_rows = [
      {
        timestamp: date,
        latency: ping + " Ms",
        download: download + " Mbps",
        upload: upload + " Mbps",
      },
    ];

    doc.autoTable(basic_columns, basic_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      margin: { top: 160, left: 60, right: 60 },
    });

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 240, "Advanced Performance Test -");

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.cell(60, 260, 475, 22, "Download Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      282,
      475,
      22,
      "Download speed achieved during the test was " + download + " Mbps"
    );

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("bold");
    doc.cell(60, 320, 475, 22, "Upload Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      340,
      475,
      22,
      "Upload speed achieved during the test was " + upload + " Mbps"
    );

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 400, "Summary Advanced Performance Test -");

    var result_columns = [{ title: "Result Message", dataKey: "message" }];
    var result_rows = [{ message: tapMsg }];

    doc.autoTable(result_columns, result_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      startY: 420,
      margin: { top: 420, left: 60, right: 60 },
    });
  }
  if (props.htmlType == "TAP-1_PASS_BE") {
    console.log(props.htmlType);
    console.log(
      props.htmlType,
      upload,
      download,
      ping,
      upstreamIPProfileRate,
      ipProfile,
      upDSLConnectionRate,
      downDSLConnectionRate,
      maxAchievableDownSpeed,
      lowAcceptableSpeedRange,
      tapMsg,
      resultMsg,
      highAcceptableSpeedRange
    );

    doc.setFont("Times New Roman");
    doc.setFontType("normal");
    doc.setFontSize(20);
    doc.setFontStyle("bold");
    doc.setTextColor(255, 0, 0);
    doc.text(60, 80, "Performance Test Results Report");

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.text(60, 140, "Basic Performance Test -");
    var basic_columns = [
      { title: "Time Stamp", dataKey: "timestamp" },
      { title: "Latency", dataKey: "latency" },
      { title: "Download", dataKey: "download" },
      { title: "Upload", dataKey: "upload" },
    ];
    var basic_rows = [
      {
        timestamp: date,
        latency: ping + " Ms",
        download: download + " Mbps",
        upload: upload + " Mbps",
      },
    ];

    doc.autoTable(basic_columns, basic_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      margin: { top: 160, left: 60, right: 60 },
    });

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 240, "Advanced Performance Test -");

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.cell(60, 260, 475, 22, "Download Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      282,
      475,
      22,
      "Download speed achieved during the test was " + download + " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      304,
      475,
      22,
      "For your connection, the acceptable range of speeds is  " +
        lowAcceptableSpeedRange +
        "-" +
        highAcceptableSpeedRange +
        " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      326,
      475,
      22,
      "Your DSL Connection Rate: " +
        downDSLConnectionRate +
        " Mbps" +
        "(DOWN-STREAM)," +
        upDSLConnectionRate +
        " Mbps" +
        "(UP-STREAM)"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      348,
      475,
      22,
      "For your connection, the maximum achievable download speed is " +
        maxAchievableDownSpeed +
        " Mbps"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      370,
      475,
      22,
      "IP Profile for your line is " + ipProfile + " Mbps"
    );

    var msg1 =
      "Please visit the Speed Test Guidance if you are unable to understand the test results.";
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 430, "Summary Advanced Performance Test -");

    var result_columns = [{ title: "Result Message", dataKey: "message" }];
    var result_rows = [{ message: resultMsg + " " + msg1 }];

    doc.autoTable(result_columns, result_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      startY: 450,
      margin: { top: 450, left: 60, right: 60 },
    });
  }
  if (props.htmlType == "TAP-1_PASS_BE_UPSTREAM") {
    console.log(props.htmlType);
    console.log(
      props.htmlType,
      upload,
      download,
      ping,
      upstreamIPProfileRate,
      ipProfile,
      upDSLConnectionRate,
      downDSLConnectionRate,
      maxAchievableDownSpeed,
      lowAcceptableSpeedRange,
      tapMsg,
      resultMsg,
      highAcceptableSpeedRange
    );
    doc.setFont("Times New Roman");
    doc.setFontType("normal");
    doc.setFontSize(20);
    doc.setFontStyle("bold");
    doc.setTextColor(255, 0, 0);
    doc.text(60, 80, "Performance Test Results Report");

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.text(60, 140, "Basic Performance Test -");
    var basic_columns = [
      { title: "Time Stamp", dataKey: "timestamp" },
      { title: "Latency", dataKey: "latency" },
      { title: "Download", dataKey: "download" },
      { title: "Upload", dataKey: "upload" },
    ];
    var basic_rows = [
      {
        timestamp: date,
        latency: ping + " Ms",
        download: download + " Mbps",
        upload: upload + " Mbps",
      },
    ];

    doc.autoTable(basic_columns, basic_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      margin: { top: 160, left: 60, right: 60 },
    });

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 240, "Advanced Performance Test -");

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.cell(60, 260, 475, 22, "Download Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      282,
      475,
      22,
      "Download speed achieved during the test was " + download + " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      304,
      475,
      22,
      "For your connection, the acceptable range of speeds is  " +
        lowAcceptableSpeedRange +
        "-" +
        highAcceptableSpeedRange +
        " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      326,
      475,
      22,
      "Your DSL Connection Rate: " +
        downDSLConnectionRate +
        " Mbps" +
        "(DOWN-STREAM)," +
        upDSLConnectionRate +
        " Mbps" +
        "(UP-STREAM)"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      348,
      475,
      22,
      "For your connection, the maximum achievable download speed is " +
        maxAchievableDownSpeed +
        " Mbps"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      370,
      475,
      22,
      "IP Profile for your line is " + ipProfile + " Mbps"
    );

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("bold");
    doc.cell(60, 410, 475, 22, "Upload Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      430,
      475,
      22,
      "Upload speed achieved during the test was " + upload + " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      452,
      475,
      22,
      "For your connection, the maximum achievable upload speed is " +
        upstreamIPProfileRate +
        " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      474,
      475,
      22,
      "IP Profile for your line is " + upstreamIPProfileRate + " Mbps"
    );

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 530, "Summary Advanced Performance Test -");
    var msg1 =
      "Please visit the Speed Test Guidance if you are unable to understand the test results.";

    var result_columns = [{ title: "Result Message", dataKey: "message" }];
    var result_rows = [{ message: resultMsg + " " + msg1 }];

    doc.autoTable(result_columns, result_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      startY: 550,
      margin: { top: 550, left: 60, right: 60 },
    });
  }
  if (props.htmlType == "TAP-2_FAIL_BE") {
    console.log(
      props.htmlType,
      upload,
      download,
      ping,
      upstreamIPProfileRate,
      ipProfile,
      upDSLConnectionRate,
      downDSLConnectionRate,
      maxAchievableDownSpeed,
      lowAcceptableSpeedRange,
      tapMsg,
      resultMsg,
      highAcceptableSpeedRange
    );
    console.log(props.htmlType);
    doc.setFont("Times New Roman");
    doc.setFontType("normal");
    doc.setFontSize(20);
    doc.setFontStyle("bold");
    doc.setTextColor(255, 0, 0);
    doc.text(60, 80, "Performance Test Results Report");

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.text(60, 140, "Basic Performance Test -");
    var basic_columns = [
      { title: "Time Stamp", dataKey: "timestamp" },
      { title: "Latency", dataKey: "latency" },
      { title: "Download", dataKey: "download" },
      { title: "Upload", dataKey: "upload" },
    ];
    var basic_rows = [
      {
        timestamp: date,
        latency: ping + " Ms",
        download: download + " Mbps",
        upload: upload + " Mbps",
      },
    ];

    doc.autoTable(basic_columns, basic_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      margin: { top: 160, left: 60, right: 60 },
    });

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 240, "Advanced Performance Test -");
    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.cell(60, 260, 475, 22, "Download Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      282,
      475,
      22,
      "Download speed achieved during the test was " + download + " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      304,
      475,
      22,
      "For your connection, the acceptable range of speeds is  " +
        lowAcceptableSpeedRange +
        "-" +
        highAcceptableSpeedRange +
        " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      326,
      475,
      22,
      "Your DSL Connection Rate: " +
        downDSLConnectionRate +
        " Mbps" +
        "(DOWN-STREAM)," +
        upDSLConnectionRate +
        " Mbps" +
        "(UP-STREAM)"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      348,
      475,
      22,
      "For your connection, the maximum achievable download speed is " +
        maxAchievableDownSpeed +
        " Mbps"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      370,
      475,
      22,
      "IP Profile for your line is " + ipProfile + " Mbps"
    );

    var msg1 =
      "Please visit the Speed Test Guidance if you are unable to understand the test results.";
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 430, "Summary Advanced Performance Test -");

    var result_columns = [{ title: "Result Message", dataKey: "message" }];
    var result_rows = [{ message: resultMsg + " " + msg1 }];

    doc.autoTable(result_columns, result_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      startY: 450,
      margin: { top: 450, left: 60, right: 60 },
    });
  }
  if (props.htmlType == "TAP-2_FAIL_BE_UPSTREAM") {
    console.log(
      props.htmlType,
      upload,
      download,
      ping,
      upstreamIPProfileRate,
      ipProfile,
      upDSLConnectionRate,
      downDSLConnectionRate,
      maxAchievableDownSpeed,
      lowAcceptableSpeedRange,
      tapMsg,
      resultMsg,
      highAcceptableSpeedRange
    );
    console.log(props.htmlType);
    doc.setFont("Times New Roman");
    doc.setFontType("normal");
    doc.setFontSize(20);
    doc.setFontStyle("bold");
    doc.setTextColor(255, 0, 0);
    doc.text(60, 80, "Performance Test Results Report");

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.text(60, 140, "Basic Performance Test -");
    var basic_columns = [
      { title: "Time Stamp", dataKey: "timestamp" },
      { title: "Latency", dataKey: "latency" },
      { title: "Download", dataKey: "download" },
      { title: "Upload", dataKey: "upload" },
    ];
    var basic_rows = [
      {
        timestamp: date,
        latency: ping + " Ms",
        download: download + " Mbps",
        upload: upload + " Mbps",
      },
    ];

    doc.autoTable(basic_columns, basic_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      margin: { top: 160, left: 60, right: 60 },
    });

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 240, "Advanced Performance Test -");

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.cell(60, 260, 475, 22, "Download Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      282,
      475,
      22,
      "Download speed achieved during the test was " + download + " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      304,
      475,
      22,
      "For your connection, the acceptable range of speeds is  " +
        lowAcceptableSpeedRange +
        "-" +
        highAcceptableSpeedRange +
        " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      326,
      475,
      22,
      "Your DSL Connection Rate: " +
        downDSLConnectionRate +
        " Mbps" +
        "(DOWN-STREAM)," +
        upDSLConnectionRate +
        " Mbps" +
        "(UP-STREAM)"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      348,
      475,
      22,
      "For your connection, the maximum achievable download speed is " +
        maxAchievableDownSpeed +
        " Mbps"
    );

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      370,
      475,
      22,
      "IP Profile for your line is " + ipProfile + " Mbps"
    );

    doc.setTextColor("#FFFFFF");
    doc.setDrawColor(0, 76, 153);
    doc.printingHeaderRow = true;
    doc.setFillColor(0, 153, 76);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("bold");
    doc.cell(60, 410, 475, 22, "Upload Test"); //cell(x,y,w,h,text,i)

    doc.setTextColor("#606060");
    doc.setDrawColor(224, 224, 224);
    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.setFont("Times New Roman");
    doc.setFontSize(11);
    doc.setFontStyle("normal");
    doc.cell(
      60,
      430,
      475,
      22,
      "Upload speed achieved during the test was " + upload + " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      452,
      475,
      22,
      "For your connection, the maximum achievable upload speed is " +
        upstreamIPProfileRate +
        " Mbps"
    ); //cell(x,y,w,h,text,i)

    doc.printingHeaderRow = true;
    doc.setFillColor(240, 240, 240);
    doc.cell(
      60,
      474,
      475,
      22,
      "IP Profile for your line is " + upstreamIPProfileRate + " Mbps"
    );

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 102);
    doc.setFontStyle("bold");
    doc.text(60, 530, "Summary Advanced Performance Test -");
    var msg1 =
      "Please visit the Speed Test Guidance if you are unable to understand the test results.";

    var result_columns = [{ title: "Result Message", dataKey: "message" }];
    var result_rows = [{ message: resultMsg + " " + msg1 }];

    doc.autoTable(result_columns, result_rows, {
      styles: {
        fillColor: [0, 153, 76],
        font: "Times New Roman",
        fontSize: 11,
      },
      columnStyles: {
        id: { fillColor: 255 },
      },
      startY: 550,
      margin: { top: 550, left: 60, right: 60 },
    });
  }
  doc.save("PerformanceTestReport.pdf");
};

const showComponent = (props) => {
  console.log(props.htmlType);
  if (props.htmlType == "SIMPLE_BE") {
    console.log("simple BE 1");
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div
                className="col-12"
                style={{
                  color: "#3C0E92",
                  fontWeight: "bold",
                  fontSize: 15,
                  marginTop: 8,
                }}
              >
                Advance Test
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="col-12 margin-for-download-t1 style-download">
                    Download Test
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: 100,
                      color: "#3C0E92",
                    }}
                  >
                    <span style={{ color: "#FF90FF" }}>Download speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.download} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4">{}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                <div
                  className="col-12"
                  style={{
                    color: "#3C0E92",
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: 8,
                  }}
                >
                  Summary Advanced Test
                </div>
              </div>
              <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                <div
                  className="col-12"
                  style={{
                    marginLeft: 1,
                    marginBottom: 8,
                    color: "#3C0E92",
                    fontSize: 12,
                  }}
                >
                  <p className="addMarginIE">{props.tapMsg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (props.htmlType == "SIMPLE_BE_UPSTREAM") {
    console.log("simple BE 2");
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div
                className="col-12"
                style={{
                  color: "#3C0E92",
                  fontWeight: "bold",
                  fontSize: 15,
                  marginTop: 8,
                }}
              >
                Advance Test
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="col-12 margin-for-download-t1 style-download-t2">
                    Download Test
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: 6,
                      color: "#3C0E92",
                    }}
                  >
                    <span style={{ color: "#FF90FF" }}>Download speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.download} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div
                    className="col-12"
                    style={{ fontSize: 14, fontWeight: "bold", marginTop: 8 }}
                  >
                    Upload Test
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: 9,
                      color: "#3C0E92",
                    }}
                  >
                    <span style={{ color: "#FF90FF" }}>Upload speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.upload} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                <div
                  className="col-12"
                  style={{
                    color: "#3C0E92",
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: 8,
                  }}
                >
                  Summary Advanced Test
                </div>
              </div>
              <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                <div
                  className="col-12"
                  style={{
                    marginLeft: 1,
                    marginBottom: 8,
                    color: "#3C0E92",
                    fontSize: 12,
                  }}
                >
                  <p className="addMarginIE">{props.tapMsg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (props.htmlType == "TAP-1_PASS_BE") {
    console.log("simple BE 3");
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div
                className="col-12"
                style={{
                  color: "#3C0E92",
                  fontWeight: "bold",
                  fontSize: 15,
                  marginTop: 8,
                }}
              >
                Advance Test
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="col-12 margin-for-download-t1 style-download">
                    Download Test
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    <span style={{ color: "#FF90FF" }}>Download speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.download} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    For your connection, the acceptable{" "}
                    <span style={{ color: "#FF90FF" }}>range of speeds</span> is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.lowAcceptableSpeedRange} -{" "}
                      {props.highAcceptableSpeedRange} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 14, marginTop: 3 }}
                  >
                    Additional Information:
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    Your DSL Connection Rate:{" "}
                    <span style={{ color: "#FF90FF" }}>
                      {props.downDSLConnectionRate}(DOWN-STREAM),{" "}
                      {props.upDSLConnectionRate}(UP-STREAM){" "}
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: 8,
                      color: "#3C0E92",
                    }}
                  >
                    IP Profile for your line is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.ipProfile} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4 margin-for-download">
                  {showProgress(props.download, props.maxAchievableDownSpeed)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    color: "#3C0E92",
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: 8,
                  }}
                >
                  Summary Advanced Test
                </div>
              </div>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    marginLeft: 1,
                    marginBottom: 8,
                    color: "#3C0E92",
                    fontSize: 12,
                  }}
                >
                  <p className="addMarginIE">
                    {props.resultMsg}
                    <br />
                    <div>
                      Please visit the Speed Test Guidance if you are unable to
                      understand the test results.
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (props.htmlType == "TAP-1_PASS_BE_UPSTREAM") {
    console.log("simple BE 4");
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div
                className="col-12"
                style={{
                  color: "#3C0E92",
                  fontWeight: "bold",
                  fontSize: 15,
                  marginTop: 8,
                }}
              >
                Advance Test
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="col-12 margin-for-download-t1 style-download">
                    Download Test
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    <span style={{ color: "#FF90FF" }}>Download speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.download} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    For your connection, the acceptable{" "}
                    <span style={{ color: "#FF90FF" }}>range of speeds</span> is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.lowAcceptableSpeedRange} -{" "}
                      {props.highAcceptableSpeedRange} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    IP Profile for your line is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.ipProfile} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4 margin-for-download">
                  {showProgress(props.download, props.maxAchievableDownSpeed)}
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div
                    className="col-12"
                    style={{ fontSize: 14, fontWeight: "bold", marginTop: 8 }}
                  >
                    Upload Test
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    <span style={{ color: "#FF90FF" }}>Upload speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.upload} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: 8,
                      color: "#3C0E92",
                    }}
                  >
                    IP Profile for your line is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.upstreamIPProfileRate} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  {showProgress(props.upload, props.upstreamIPProfileRate)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    color: "#3C0E92",
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: 8,
                  }}
                >
                  Summary Advanced Test
                </div>
              </div>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    marginLeft: 1,
                    marginBottom: 8,
                    color: "#3C0E92",
                    fontSize: 12,
                  }}
                >
                  <p className="addMarginIE">
                    <br />
                    <span style={{ color: "#bf0a1b" }}>
                      We were unable to identify any performance problem with
                      your service at this time.
                    </span>
                    <br />
                    <br />
                    It is possible that any problem you have experienced may
                    have been caused by traffic congestion or by a server
                    responding slowly.
                    <br />
                    <br />
                    <div>
                      Please visit the{" "}
                      <span style={{ color: "#08C7BF" }}>
                        "SpeedTest Guidance"
                      </span>{" "}
                      for more information.
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (props.htmlType == "TAP-2_FAIL_BE") {
    console.log("simple BE 5");
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div
                className="col-12"
                style={{
                  color: "#3C0E92",
                  fontWeight: "bold",
                  fontSize: 15,
                  marginTop: 8,
                }}
              >
                Advance Test
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="col-12 margin-for-download-t1 style-download">
                    Download Test
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    <span style={{ color: "#FF90FF" }}>Download speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.download} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    For your connection, the acceptable{" "}
                    <span style={{ color: "#FF90FF" }}>range of speeds</span> is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.lowAcceptableSpeedRange} -{" "}
                      {props.highAcceptableSpeedRange} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 14, marginTop: 3 }}
                  >
                    Additional Information:
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    Your DSL Connection Rate:{" "}
                    <span style={{ color: "FF90FF" }}>
                      {props.downDSLConnectionRate}(DOWN-STREAM),{" "}
                      {props.upDSLConnectionRate}(UP-STREAM)
                    </span>{" "}
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: 8,
                      color: "#3C0E92",
                    }}
                  >
                    IP Profile for your line is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.ipProfile} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4 margin-for-download">
                  {showProgress(props.download, props.maxAchievableDownSpeed)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    color: "#3C0E92",
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: 8,
                  }}
                >
                  Summary Advanced Test
                </div>
              </div>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    marginLeft: 1,
                    marginBottom: 8,
                    color: "#3C0E92",
                    fontSize: 12,
                  }}
                >
                  <p className="addMarginIE">
                    {props.resultMsg}
                    <br />
                    <div>
                      Please close the window using 'CLOSE' button and contact
                      your Broadband provider for further assistance with these
                      results.
                    </div>
                    <div>
                      <span style={{ color: "#bf0a1b" }}>
                        Please visit the{" "}
                      </span>
                      <span style={{ color: "#08C7BF" }}>
                        "SpeedTest Guidance"
                      </span>
                      <span style={{ color: "#bf0a1b" }}>
                        {" "}
                        if you�d like more information to understand the test
                        results.
                      </span>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (props.htmlType == "TAP-2_FAIL_BE_UPSTREAM") {
    console.log("simple BE 6");
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div
                className="col-12"
                style={{
                  color: "#3C0E92",
                  fontWeight: "bold",
                  fontSize: 15,
                  marginTop: 8,
                }}
              >
                Advance Test
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="col-12 margin-for-download-t1 style-download">
                    Download Test
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    <span style={{ color: "#FF90FF" }}>Download speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.download} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    For your connection, the acceptable{" "}
                    <span style={{ color: "#FF90FF" }}>range of speeds</span> is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.lowAcceptableSpeedRange} -{" "}
                      {props.highAcceptableSpeedRange} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    IP Profile for your line is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.ipProfile} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  {showProgress(props.download, props.maxAchievableDownSpeed)}
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div
                    className="col-12"
                    style={{ fontSize: 14, fontWeight: "bold", marginTop: 8 }}
                  >
                    Upload Test
                  </div>
                  <div
                    className="col-12"
                    style={{ fontSize: 12, marginTop: 3, color: "#3C0E92" }}
                  >
                    <span style={{ color: "#FF90FF" }}>Upload speed</span>{" "}
                    achieved during the test was{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.upload} Mbps
                    </span>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: 8,
                      color: "#3C0E92",
                    }}
                  >
                    IP Profile for your line is{" "}
                    <span style={{ color: "#FF90FF", fontWeight: "bold" }}>
                      {props.upstreamIPProfileRate} Mbps
                    </span>
                  </div>
                </div>
                <div className="col-4 margin-for-download">
                  {showProgress(props.upload, props.upstreamIPProfileRate)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3" style={{ borderColor: "#FF90FF" }}>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    color: "#3C0E92",
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: 8,
                  }}
                >
                  Summary Advanced Test
                </div>
              </div>
              <div className="row" style={{ marginLeft: 0 }}>
                <div
                  className="col-12"
                  style={{
                    marginLeft: 1,
                    marginBottom: 8,
                    color: "#3C0E92",
                    fontSize: 12,
                  }}
                >
                  <p className="addMarginIE">
                    {props.resultMsg}
                    <br />
                    <div>
                      Please close the window using 'CLOSE' button and contact
                      your Broadband provider for further assistance with these
                      results.
                    </div>
                    <div>
                      <span style={{ color: "#bf0a1b" }}>
                        Please visit the{" "}
                      </span>
                      <span style={{ color: "#08C7BF" }}>
                        "SpeedTest Guidance"
                      </span>
                      <span style={{ color: "#bf0a1b" }}>
                        {" "}
                        if you'd like more information to understand the test
                        results.
                      </span>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

// const finalResult = (props) => {
class finalResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleClose = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    return (
      <div id="divToPrint">
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="card  mb-3" style={{ borderColor: "#FF90FF" }}>
                <div
                  className="col-12"
                  style={{
                    color: "#3C0E92",
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: 8,
                  }}
                >
                  Summary of Performance Test
                </div>
                <div
                  className="row"
                  style={{ marginLeft: 1, marginBottom: 8, color: "#3C0E92" }}
                >
                  <div className="col-6 margin-ie font-space-for-result-summary">
                    Timestamp
                    <div className="style-for-div-summary">{date}</div>
                  </div>
                  <div className="col-2 margin-ie font-space-for-result-summary">
                    Ping
                    <div className="style-for-div-summary">
                      {this.props.ping} Ms
                    </div>
                  </div>
                  <div className="col-2 margin-ie font-space-for-result-summary">
                    Download
                    <div className="style-for-div-summary">
                      {this.props.download} Mbps
                    </div>
                  </div>
                  <div className="col-2 margin-ie font-space-for-result-summary">
                    Upload
                    <div className="style-for-div-summary">
                      {this.props.upload} Mbps
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {console.log("show components")}
          {console.log("props---->", this.props)}
          {showComponent(this.props)}
        </div>
        <table>
          <tbody>
            <tr>
              <td  style={{
                    width: "150px"
                  }}>
                <button
                  id="print"
                  style={{
                    backgroundColor: "#3C0E92",
                    color: "#FFFFFF",
                    fontStyle: "sans-serif",
                    width: "max-content"
                  }}
                  onClick={() => printDocument(this.props)}
                >
                  Download Report
                </button>
              </td>{" "}
              <td>
                <p
                  style={{
                    color: "#3c0e92",
                    fontWeight: "bold",
                    marginTop: "5px",
                    marginLeft: "10px",
                    fontSize: "14px"
                  }}
                >
                  The speed tests for 500Mb/1000Mb product are dependent on device capability.{" "}
                  <button
                    className="clickHereBtnBlue"
                    onClick={() => this.handleClose()}
                  >
                    Click here
                  </button>{" "}
                  for more information.
                </p>
              </td>{" "}
            </tr>
          </tbody>
        </table>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          style={{ maxWidth: "100%" }}
        >
          <div
            style={{ width: "100%", marginTop: "10px", paddingRight: "10px" }}
          >
            <button
              onClick={() => this.handleClose()}
              className="rectangleGuidance"
              style={{
                float: "right",
                fontWeight: "bold",
                textAlign: "end",
                margin: "auto",
              }}
            >
              [CLOSE]
            </button>
            <br />
            <p
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              When testing 500Mb/1000Mb speeds via a computer based device the results are dependent on the capability of the device and the premises environment.
              <br />
              In addition to the standard guidance provided in the ‘Before You Start?’ section you need to ensure that the customers device meets the following minimum specification:{" "}
            </p>
            <ol
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              <li>
              Processor should be above or equivalent to Intel® Core™ i5-4570S Processor (4 cores, 3.80 max Turbo frequency, 2.90GHz processor frequency, 6MB smart cache){" "}
              </li>
              <li>Ensure that at least 4 GB of RAM is free</li>
              <li>1 GB NIC card is recommended</li>
              <li>
              Ethernet cable should have the capability to transfer 1GB data{" "}
              </li>
              <li>Operating System - Windows 8 and above</li>
              <li>
              If you experience any issues when trying to run the performance test, please ensure you are using the latest browser version for optimum results{" "}
              </li>
            </ol>
            <p
              style={{
                color: "#3c0e92",
                margin: 20,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              {" "}
              Please note that inaccurate results may be seen if the minimum specification isn't met.
            </p>
            <br />
          </div>
        </Modal>
      </div>
    );
  }
}

export default finalResult;
