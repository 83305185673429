import * as speedtest from './speed.test.js';



var speed = "";
var tap = "";
var duration = "";
var thread = "";
var function_name="";

export function OpenFaq(question)
{
	var linkPage = "FAQ.htm";
	if (question != "")
	{
		linkPage = linkPage + "#G" + question;
	}
	var windowName = 'window' + new Date().getTime();
	window.open(linkPage,windowName,'dependent=yes, toolbar=no, status=yes, titlebar=no, location=no , scrollbars=yes, menubar=no, resizable=yes, height=700, width=800');
}

export function validation()
{var test_started = false;
		if(test_started == true)	{
      		return;   
    	}
		
		//var editemail=window.document.validationform.email.value.split("@");
		var BBIP=window.document.validationform.BBIP.value
	    //var user=editemail[0];
	    //var domain=editemail[1];
		//Changes for ST-PT-T2R-004/005 by Vaibhav_Pandey01 28 May 2009: 
		//Changed to read the user name/ domain name from seperate fields
		//on the asp page.
		var user = window.document.validationform.user.value;
		var domain = window.document.validationform.domain.value;
		var serviceId = window.document.validationform.serviceId.value;
		if (user == "")
		{
			alert("The User Name field cannot be blank. Please enter your User/Login name.");
			return;
		}
		if (domain == "")
		{
			//Changes for ST-PT-T2R-005 by Vaibhav_Pandey01 28 May 2009:
			//Since we have to allow user to enter blank domain id, so we need to
			//set the blank domain to some dummy value so that it passes through 
			//all the 'not null' checks in the back end code. This is a fail safe 
			//condition as the value of domain name is no longer significant after 
			//this change. Domain ID will not be used for authentication purpose anymore
			//domain="BLANK";
		}
		speedtest.getInfo();
		if(test_started != true)	{
			test_started = true;
		}
		window.document.validationform.submit();
		//window.document.location.replace("initialise_session.do?BBIP="+BBIP.toUpperCase()+"&user="+user+"&domain="+domain+"&OS="+os+"&BROWSER="+browser+"&VERSION="+ver+"&USRAGENT="+usrr+"&serviceId=" +serviceId); 

}// function validate ends.

export function startTest(upload,download,start,end,startUpTime,endUpTime,uploadSize,bytes,broadbandId,Username,sBBIP)
{var test_started = false;
	var serveletArray=[];
	//console.log("In the startTest Javascript");
	 
	  
	  
	  var down=download;
	  var up=upload;
	  var totalbytes=bytes;
	  var start=start;
	  var end=end;
	  var startUpTime=startUpTime;
	  var endUpTime=endUpTime;
	  var uploadSize=uploadSize;
     if(test_started == true)
     {
		 //console.log("inside test started 1")
      return;   
     }

	// Check the form value fields in the data form.
	//console.log("inside test started 2")
	var sBBIP	= new String(sBBIP);
	//console.log("inside test started 3",sBBIP)
	var rExp 	= / /gi;
	//console.log("4")
	sBBIP		= sBBIP.replace(rExp,'');	
	//console.log("5"+sBBIP)					// Strip Spaces
	var sLead	= sBBIP.substring(0,4).toUpperCase();
	//console.log("6",sLead)
	var sOther	= sBBIP.substring(4,13);
	//console.log("7",sOther)
	sBBIP	= sBBIP.toUpperCase();	
	//console.log("8",sBBIP)				// Make it uppercase
    
	//Aceepting the Service Id as input.
	var sserviceId = new String(broadbandId);
	//console.log("9",sserviceId)

	//Patterns for matching with Service ID.
	var patt1 = new RegExp("^[B][B][I][P]\\d{1,8}$","i");
	var patt2 = new RegExp("^[B][B][D][S]\\d{1,8}$","i");
	var patt3 = new RegExp("^[B][B][V][S]\\d{1,8}$","i");
	var patt4 = new RegExp("^[B][B][E][U]\\d{8}$","i");
	var patt5 = new RegExp("^[F][T][I][P]\\d{1,9}$","i");
	var patt6 = new RegExp("^[C][B][U][K]\\d{1,8}$","i");

	// Not currently using, commented out by JT ------- setCookie();

	// Check to see if username is available from the form
	if (Username)
	{//console.log("10",Username)
		
		var sUsername = new String(Username);
		//console.log("11",sUsername)
		Username	= sUsername.toUpperCase();					// Make it uppercase
		if(sUsername.length > 0 )
		{//console.log("12",Username.length)
			/* The following pattern is used to check if the entered username domain
			fits the user@domain format.  It also is used to separate the username
			from the domain. */
			var emailPat=/^(.+)@(.+)$/
			var specialChars="\\(\\)<>@,;:\\\\\\\"\\.\\[\\]"
			var validChars="\[^\\s" + specialChars + "\]"
			var quotedUser="(\"[^\"]*\")"
			var atom=validChars + '+'
			var word="(" + atom + "|" + quotedUser + ")"
			var userPat=new RegExp("^" + word + "(\\." + word + ")*$")
			var domainPat=new RegExp("^" + atom + "(\\." + atom +")*$")

			var matchArray=sUsername.match(emailPat)
			if (matchArray==null)
			{
				alert("Email address seems incorrect (check @ and .'s)")
				return;
			}
			var user=matchArray[1]
			var domain=matchArray[2]

			// See if "user" is valid
			if (user.match(userPat)==null) {
				// user is not valid
				alert("The username doesn't seem to be valid.")
				return;
			}

			// Domain is symbolic name
			var domainArray=domain.match(domainPat)
			if (domainArray==null)
			{
				alert("The domain name doesn't seem to be valid.")
				return;
			}

		}
		else
		{
			alert("Please insert your Username / Domain");
			return;
		}
	}
	else
	{
		var user="NOTUSED";
		var domain="NOTUSED";					// Set sUsername as not used if it is not present
	
	}

	// Check that the user has either entered something or that the field is not required

	if(user!=null && domain!=null)
	{		//console.log("12",user,domain)
		if(sBBIP.length == 0 && sserviceId.length == 0)
		{//	console.log("12",user,domain)
		//console.log("13",sBBIP.length,domain)
			alert("Please insert your Telephone number Or Service Id");
		}
		else if((sBBIP.length != 0 && (sLead.substring(0,1) == "0" || sBBIP.substring(0,1) == "+")) || sserviceId.length != 0)			// Start of phone number
		{		//console.log("14",sBBIP.length,sLead.substring(0,1),sBBIP.substring(0,1), sserviceId.length)	;
				if(sBBIP.length < 10 && sBBIP.length!=0)
			
				{	//console.log("15",sBBIP.length);
					alert("Please insert your telephone number. You must enter the area code as well as the number. Blah blah blah");
					serveletArray.push("Error")
				return serveletArray;
				}
				var dnStart = new String(sBBIP.substring(0,2));
				if(dnStart == "07" || dnStart == "08" || dnStart == "09" || dnStart == "00" || dnStart.substring(0,1) == "+")
				{	//console.log("16",dnStart,dnStart.substring(0,1))	
					alert("Please insert a UK residential phone number including the STD code e.g. 01234123456.\nInternational, Mobile, Premium Rate And Freephone Numbers Are Not Acceptable.");
					serveletArray.push("Error")
				return serveletArray;
				}
				else if(sBBIP.search(/[^0-9]/) > 0)
				{//console.log("17",sBBIP.search(/[^0-9]/))
					alert("The telephone number should only contain numerical values.\nPlease re-insert your telephone number.");
					serveletArray.push("Error")
				return serveletArray;
				}
            
			if (sserviceId.length !=0)
			{//console.log("18",sserviceId.length)
				if((!((sserviceId.match(patt1))||(sserviceId.match(patt2))||(sserviceId.match(patt3))||(sserviceId.match(patt4))||(sserviceId.match(patt5))||(sserviceId.match(patt6)))))
				{
					//console.log("19",sserviceId.length)
				alert("Please insert a valid Service Id");
				serveletArray.push("Error")
				return serveletArray;
				}	
			}

			if (sserviceId.length !=0 && sBBIP.length != 0)
			{//console.log("20---------",sserviceId.length)
				if (window.confirm('You have entered '+sBBIP+' as your Telephone Number and '+sserviceId+' as your Service ID.Click OK to proceed if this is correct ') == false)
				{//console.log("21-----------",sserviceId.length)
					window.document.data.BBIP.value = "";
					window.document.data.serviceId.value = "";
					serveletArray.push("Error")
					return serveletArray;
				}
			}
			else if(sBBIP.length != 0)
			{//console.log("22",sBBIP.length)
				if (window.confirm('You have entered '+sBBIP+' as your Telephone Number.Click OK to proceed if this is correct ') == false)
				{//console.log("23------------",sBBIP.length)
					window.document.data.BBIP.value = "";
					serveletArray.push("Error")
				return serveletArray;
				}
			}
			else
			{//console.log("24-----------",sBBIP.length)
				if (window.confirm('You have entered '+sserviceId+' as your Service Id..Click OK to proceed if this is correct ') == false)
				{//console.log("25-------------",sBBIP.length)
					window.document.data.serviceId.value = "";
					serveletArray.push("Error")
				return serveletArray;
				}
			}

			
			speedtest.getInfo();
			//console.log("26------",sBBIP.length)
			var usrr="Mozilla%2F5.0%20(Windows%20NT%206.3%3B%20Win64%3B%20x64)%20AppleWebKit%2F537.36%20(KHTML%2C%20like%20Gecko)%20Chrome%2F79.0.3945.130%20Safari%2F537.36";
			//console.log("27----------",sBBIP.length)
			usrr = urlEncode(usrr);
			//console.log("28------------",sBBIP.length)
			if(test_started != true){
				//console.log("29-----------",sBBIP.length)
				test_started = true;}else {
					//console.log("30----------",sBBIP.length)
					return ;}
			//To log java version. should be removed
			var ver;
			ver="";			
			//console.log("I ma before servlet call");
			
			 	 
			serveletArray.push(sBBIP.toUpperCase());
			serveletArray.push(user.toUpperCase());
			serveletArray.push(domain.toUpperCase());
			serveletArray.push("Windows%20NT%206.3%20x64");
			serveletArray.push("Mozilla");
			serveletArray.push(ver);
			serveletArray.push(usrr);
			serveletArray.push(sserviceId.toUpperCase());
			serveletArray.push(up);
			serveletArray.push(down);
			serveletArray.push(totalbytes);
			serveletArray.push(start);
			serveletArray.push(end);
			serveletArray.push(startUpTime);
			serveletArray.push(endUpTime);
			serveletArray.push(uploadSize);
			//console.log("ARRAY----->",serveletArray);
			//console.log("31-------------",sBBIP.length)
			//window.document.location.replace("initialise_session.do?BBIP="+sBBIP.toUpperCase()+"&user="+user.toUpperCase()+"&domain="+domain.toUpperCase()+"&OS=Windows%20NT%206.3%20x64"+"&BROWSER=Mozilla"+"&VERSION="+ver+"&USRAGENT="+usrr+"&serviceId="+sserviceId.toUpperCase()+"&up="+up+"&down="+down+"&totalbytes="+totalbytes+"&start="+start+"&end="+end+"&startUpTime="+startUpTime+"&endUpTime="+endUpTime+"&uploadSize="+uploadSize); // Initialise the session
			return serveletArray;
		}		
		else
		{//console.log("32-------------",sBBIP.length)
		    alert("Please insert your telephone number. You must enter the area code as well as the number. blah blah blah");
			serveletArray.push("Error")
				return serveletArray;
		}
	}
	
}
export function ReplaceAll(varb, replaceThis, replaceBy)
{ 

      var newvarbarray=varb.split(replaceThis);
      var newvarb=newvarbarray.join(replaceBy);
	  return newvarb;
	 
}

export function urlEncode(text)
{
	  
       text=ReplaceAll(text, "\/", "%2F");
       text=ReplaceAll(text, "?", "%3F");
       text=ReplaceAll(text, "=", "%3D");
       text=ReplaceAll(text, "&", "%26");
       text=ReplaceAll(text, ",", "%2C");
       text=ReplaceAll(text, ";", "%3B");
       text=ReplaceAll(text, " ", "%20");	
      
       return text;
}

export function getKey(keyStroke)
{
	// Check for the return key anywhere on the form and action

        var keyCode = (document.layers) ? keyStroke.which : window.event.keyCode;

        if(keyCode == 13)
                startTest();
}
export function getKey2(keyStroke)
{
	// Check for the return key anywhere on the form and action

        var keyCode = (document.layers) ? keyStroke.which : window.event.keyCode;

        if(keyCode == 13)
                validation();
}

export function paintProgressAndMessageOnBar(progress , message)
{
	
	
}

export function paintProgressAndMessageTestBar(progress , message)
{
	
}

export function incrementProgressBar(progress)
{
	
}
// included for RT QoS

//included for packet drop test

// included for Upstream


// included for WCC assured test
//function StartWCCTest(serviceId,speed,tap,duration,thread)
//## END : changes for configuring number of threads

//## Begin Add for Release23 by Gagan_Shinde on 04-Dec-2008
//## New function added for Downloading images for Speed Test
//## End Add for Release23 by Gagan_Shinde on 04-Dec-2008