// Speed test calculation routine and time get function.
// version 1.0 Surendran V. 01/19/06 Initial version after improving configuration management
// version 1.1 Surendran V. 02/01/06 Check for Opera browser
// version 1.2 Surendran V. 03/26/06 changed duration to centi seconds
// version 1.3 Ashsih D. 04/12/06 Pr70 rolled back
// version 1.4 Mahendra. 04/18/06 PR70 changes added
// version 1.5 Gagan_Shinde 12/04/08 Release23 PT-ST Merger
// version 1.6 priyanka_Khamankar 20/04/09 CR 333B changes added

export var browser, os, ver, stored_startTime, usrr;

function getTime() {
  var time = new Date();
  return time.getTime();
}

function RememberStartTime(startTime) {
  stored_startTime = startTime;
}

function findDLSpeed(startTime) {
  if (window.document.data.DONE.value == 1) return;
  var timeEnd = getTime();
  setDateTime(window.document.data.ENDTIME, 0);
  var timeElapsed = (timeEnd - startTime) / 1000 - 0.15;
  var timeTaken = timeEnd - startTime;
  var kbytes = 1750 / timeElapsed;
  var bits = kbytes * 1024 * 8;
  getInfo();

  // Use form data and post form to ss script.
  window.document.data.SPEED.value = bits;
  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);
  //	window.document.data.STARTTIME.value    = escape(stored_startTime);
  //	window.document.data.ENDTIME.value	= escape(window.document.data.ENDTIME.value);
  window.document.data.STARTTIME.value = startTime;
  window.document.data.ENDTIME.value = timeEnd;

  window.document.data.DONE.value = 1;
  window.document.data.DURATION.value = timeTaken;
  window.document.data.submit();
}

export function getInfo() {
  var Component = "Unknown Browser";
  var platform = "Unknown Platform";
  var OS = "";
  var version = parseFloat(navigator.appVersion);
  var UA = navigator.userAgent;
  var ua = navigator.userAgent.toLowerCase();
  var usrr = navigator.userAgent;

  // OS
  if (
    (ua.indexOf("ppc") > 0 && ua.indexOf("mac") > 0) ||
    ua.indexOf("mac_power") > 0
  ) {
    OS = "macppc";
  } else if (
    ua.indexOf("linux 2.2") > 0 ||
    (ua.indexOf("netscape6") && ua.indexOf("linux") > 0)
  ) {
    OS = "linux2.2";
  } else if (ua.indexOf("win") > 0) {
    OS = "win32";
  }

  // Other info
  var start = UA.indexOf("(") + 1;
  var end = UA.indexOf(")");
  var str = UA.substring(start, end);
  var info = str.split("; ");

  if (ua.indexOf("msie") != -1) {
    platform = info[2];
    Component = navigator.appName;
    str = info[1].substring(5, info[1].length);
    version = parseFloat(str);
  } else if ((start = ua.indexOf("netscape6")) > 0) {
    if (info[0].toLowerCase() == "windows") {
      platform = info[2];
    } else {
      platform = info[0] + " " + info[2];
    }
    Component = "Netscape";
    version = ua.substring(start + 10, ua.length);
    if ((start = version.indexOf("b")) > 0) {
      var pr = version.substring(start + 1, version.length);
      var str = version.substring(0, version.indexOf("b"));
      version = str + " Preview Release " + pr;
    }
  } else {
    if (info[2]) {
      if (info[0].toLowerCase() == "windows") {
        platform = info[2];
      } else {
        platform = info[0] + " " + info[2];
      }
    } else {
      platform = info[0];
    }
    if (ua.indexOf("gecko") > 0) {
      Component = "Mozilla";
    } else if (ua.indexOf("nav") > 0) {
      Component = "Netscape";
    } else {
      Component = "Netscape";
    }
  }
  // Some formatting
  if (platform.indexOf("NT") != -1) {
    if (platform.indexOf("5.0") != -1) platform = "Windows 2000";
    else if (platform.indexOf("5.1") != -1) platform = "Windows XP";
  }
  if (platform == "") platform = OS;
  if (parseInt(version) == parseFloat(version)) version = version + ".0";

  browser = Component;
  os = platform;
  ver = version;
}
function testfunctionspeed() {}
function setDateTime(oObject, increment) {
  // Set to todays date
  // Netscape and IE do things differently so we have to do different things for each.

  var sXtra1 = "";
  var sXtra2 = "";
  var sXtra3 = "";
  var sXtra4 = "";
  var sXtra5 = "";
  var offset = 0;

  if (navigator.appName == "Netscape") offset = 1900;

  var dDate = new Date();

  var dDate2 = new Date(
    Date.UTC(dDate.getYear() + offset, dDate.getMonth(), dDate.getDate()) +
      86400 * 1000 * increment
  );

  if (dDate2.getDate() < 10) sXtra1 = "0";
  if (dDate2.getMonth() < 9) sXtra2 = "0";
  if (dDate.getMinutes() < 9) sXtra3 = "0";
  if (dDate.getHours() < 9) sXtra4 = "0";
  if (dDate.getSeconds() < 9) sXtra5 = "0";

  oObject.value =
    sXtra1 +
    dDate2.getDate() +
    "/" +
    sXtra2 +
    (dDate2.getMonth() + 1) +
    "/" +
    (dDate2.getYear() + offset) +
    " " +
    sXtra4 +
    dDate.getHours() +
    ":" +
    sXtra3 +
    dDate.getMinutes() +
    ":" +
    sXtra5 +
    dDate.getSeconds();
}

//Changed the name of the function from findDLSpeedForBEandAR to findDLSpeedForBEARandST
export function submitSpeed(starttime, endtime, totaltime, fileSize, speed) {
  window.parent.f2.submitSpeedToServer(
    starttime,
    endtime,
    totaltime,
    fileSize,
    speed
  );
}
export function submitSpeedValue(
  starttime,
  endtime,
  totaltime,
  fileSize,
  speed
) {
  window.parent.f2.submitSpeedToServer(
    starttime,
    endtime,
    totaltime,
    fileSize,
    speed
  );
}

export function submitSpeedToServer(
  starttime,
  endtime,
  totaltime,
  fileSize,
  speed
) {
  //if(!(window.document.data && window.document.data.DONE) || window.document.data.DONE.value == 1)
  // return;

  getInfo();

  // Use form data and post form to ss script.
  //alert('starttime ::'+starttime+' \nEndtime ::'+endtime+' totaltime::'+totaltime+' speed(Kbps)::'+speed+' Speed Kbps::'+fileSize*8*1000/1024/totaltime);
  window.document.data.SPEED.value = speed;
  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);

  window.document.data.STARTTIME.value = starttime;
  window.document.data.ENDTIME.value = endtime;
  window.document.data.FILESIZE.value = fileSize;
  window.document.data.DURATION.value = totaltime;

  window.document.data.DONE.value = 1;

  window.document.data.submit();
}

function submitDownloadSpeedValue(
  starttime,
  endtime,
  totaltime,
  fileSize,
  speed
) {
  //if(!(window.document.data && window.document.data.DONE) || window.document.data.DONE.value == 1)
  // return;

  getInfo();

  // Use form data and post form to ss script.
  //alert('starttime ::'+starttime+' \nEndtime ::'+endtime+' totaltime::'+totaltime+' speed(Kbps)::'+speed+' Speed Kbps::'+fileSize*8*1000/1024/totaltime);
  window.document.data.SPEED.value = speed;
  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);

  window.document.data.STARTTIME.value = starttime;
  window.document.data.ENDTIME.value = endtime;
  window.document.data.FILESIZE.value = fileSize;
  window.document.data.DURATION.value = totaltime;
  window.document.data.TESTNO.value = "BestEffort";
  window.document.data.DONE.value = 1;
  window.document.data.submit();
}

//Function to submit the packet drop results

function submitPacketdropResults(
  packetDropTest1,
  jitterTest1,
  latencyTest1,
  packetDropTest2,
  jitterTest2,
  latencyTest2,
  startTime,
  endTime
) {
  //alert('submitPacketdropResults invoked !!! ');

  window.parent.f2.subPacketdropResults(
    packetDropTest1,
    jitterTest1,
    latencyTest1,
    packetDropTest2,
    jitterTest2,
    latencyTest2,
    startTime,
    endTime
  );
}

function subPacketdropResults(
  packetDropTest1,
  jitterTest1,
  latencyTest1,
  packetDropTest2,
  jitterTest2,
  latencyTest2,
  startTime,
  endTime
) {
  if (
    !(window.document.data && window.document.data.DONE) ||
    window.document.data.DONE.value == 1
  )
    return;

  getInfo();

  // Use form data and post form to ss script.
  //alert('packetDropTest1 ::'+packetDropTest1+'\njitterTest1 ::'+jitterTest1+'\nlatencyTest1:: '+latencyTest1);
  //alert('packetDropTest2 ::'+packetDropTest2+'\njitterTest2 ::'+jitterTest2+'\nlatencyTest2:: '+latencyTest2);
  // \npacketDropTest2::'+packetDropTest2++' \njitterTest2 ::'+jitterTest2+' \nlatencyTest2::'+latencyTest2);

  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);

  window.document.data.DURATION.value = 60;
  window.document.data.PACKETDROPTEST1.value = packetDropTest1;
  window.document.data.JITTERTEST1.value = jitterTest1;
  window.document.data.LATENCYTEST1.value = latencyTest1;
  window.document.data.PACKETDROPTEST2.value = packetDropTest2;
  window.document.data.JITTERTEST2.value = jitterTest2;
  window.document.data.LATENCYTEST2.value = latencyTest2;
  window.document.data.STARTTIME.value = startTime;
  window.document.data.ENDTIME.value = endTime;
  window.document.data.DONE.value = 1;

  window.document.data.submit();
}

function sendWCCTestResult1(
  fileDownloadValue,
  fileDownloadStatus,
  throughputValue,
  throughputStatus,
  serviceLatencyValue,
  serviceLatencyStatus,
  pingLatencyValue,
  pingLatencyStatus,
  pingJitterValue,
  pingJitterStatus,
  startTime,
  endTime,
  stackTrace,
  bytesDownloaded,
  duration
) {
  getInfo();

  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);

  window.document.data.WCCFILEDOWNLOADVALUE.value = fileDownloadValue;
  window.document.data.WCCFILEDOWNLOADSTATUS.value = fileDownloadStatus;
  window.document.data.WCCTHROUGHPUTVALUE.value = throughputValue;
  window.document.data.WCCTHROUGHPUTSTATUS.value = throughputStatus;
  window.document.data.WCCSERVICELATENCYVALUE.value = serviceLatencyValue;
  window.document.data.WCCSERVICELATENCYSTATUS.value = serviceLatencyStatus;
  window.document.data.WCCPINGLATENCYVALUE.value = pingLatencyValue;
  window.document.data.WCCPINGLATENCYSTATUS.value = pingLatencyStatus;
  window.document.data.WCCPINGJITTERVALUE.value = pingJitterValue;
  window.document.data.WCCPINGJITTERSTATUS.value = pingJitterStatus;

  window.document.data.SPEED.value = 0;
  window.document.data.STARTTIME.value = startTime;
  window.document.data.ENDTIME.value = endTime;
  window.document.data.FILESIZE.value = bytesDownloaded;
  window.document.data.DURATION.value = duration;

  window.document.data.DONE.value = 1;

  window.document.data.EXCEPTION.value = stackTrace;

  window.document.data.submit();
}

function sendWCCTestResult2(
  fileDownloadValue,
  fileDownloadStatus,
  throughputValue,
  throughputStatus,
  serviceLatencyValue,
  serviceLatencyStatus,
  pingLatencyValue,
  pingLatencyStatus,
  pingJitterValue,
  pingJitterStatus,
  startTime,
  endTime,
  stackTrace,
  bytesDownloaded,
  duration
) {
  window.parent.f2.submitWCCTestResult(
    fileDownloadValue,
    fileDownloadStatus,
    throughputValue,
    throughputStatus,
    serviceLatencyValue,
    serviceLatencyStatus,
    pingLatencyValue,
    pingLatencyStatus,
    pingJitterValue,
    pingJitterStatus,
    startTime,
    endTime,
    stackTrace,
    bytesDownloaded,
    duration
  );
}

function submitWCCTestResultx(
  fileDownloadValue,
  fileDownloadStatus,
  throughputValue,
  throughputStatus,
  serviceLatencyValue,
  serviceLatencyStatus,
  pingLatencyValue,
  pingLatencyStatus,
  pingJitterValue,
  pingJitterStatus,
  startTime,
  endTime,
  stackTrace,
  bytesDownloaded,
  duration
) {
  if (
    !(window.document.data && window.document.data.DONE) ||
    window.document.data.DONE.value == 1
  )
    return;

  getInfo();

  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);

  window.document.data.WCCFILEDOWNLOADVALUE.value = fileDownloadValue;
  window.document.data.WCCFILEDOWNLOADSTATUS.value = fileDownloadStatus;
  window.document.data.WCCTHROUGHPUTVALUE.value = throughputValue;
  window.document.data.WCCTHROUGHPUTSTATUS.value = throughputStatus;
  window.document.data.WCCSERVICELATENCYVALUE.value = serviceLatencyValue;
  window.document.data.WCCSERVICELATENCYSTATUS.value = serviceLatencyStatus;
  window.document.data.WCCPINGLATENCYVALUE.value = pingLatencyValue;
  window.document.data.WCCPINGLATENCYSTATUS.value = pingLatencyStatus;
  window.document.data.WCCPINGJITTERVALUE.value = pingJitterValue;
  window.document.data.WCCPINGJITTERSTATUS.value = pingJitterStatus;

  window.document.data.SPEED.value = 0;
  window.document.data.STARTTIME.value = startTime;
  window.document.data.ENDTIME.value = endTime;
  window.document.data.FILESIZE.value = bytesDownloaded;
  window.document.data.DURATION.value = duration;

  window.document.data.DONE.value = 1;

  window.document.data.EXCEPTION.value = stackTrace;

  window.document.data.submit();
}

function sendWCCTestResult(
  fileDownloadValue,
  fileDownloadStatus,
  throughputValue,
  throughputStatus,
  serviceLatencyValue,
  serviceLatencyStatus,
  pingLatencyValue,
  pingLatencyStatus,
  pingJitterValue,
  pingJitterStatus,
  startTime,
  endTime,
  stackTrace,
  bytesDownloaded,
  duration,
  packetDropTest1,
  jitterTest1,
  latencyTest1
) {
  window.parent.f2.submitWCCTestResult(
    fileDownloadValue,
    fileDownloadStatus,
    throughputValue,
    throughputStatus,
    serviceLatencyValue,
    serviceLatencyStatus,
    pingLatencyValue,
    pingLatencyStatus,
    pingJitterValue,
    pingJitterStatus,
    startTime,
    endTime,
    stackTrace,
    bytesDownloaded,
    duration,
    packetDropTest1,
    jitterTest1,
    latencyTest1
  );
}

function submitWCCTestResult(
  fileDownloadValue,
  fileDownloadStatus,
  throughputValue,
  throughputStatus,
  serviceLatencyValue,
  serviceLatencyStatus,
  pingLatencyValue,
  pingLatencyStatus,
  pingJitterValue,
  pingJitterStatus,
  startTime,
  endTime,
  stackTrace,
  bytesDownloaded,
  duration,
  packetDropTest1,
  jitterTest1,
  latencyTest1
) {
  if (
    !(window.document.data && window.document.data.DONE) ||
    window.document.data.DONE.value == 1
  )
    return;

  getInfo();

  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);

  window.document.data.WCCFILEDOWNLOADVALUE.value = fileDownloadValue;
  window.document.data.WCCFILEDOWNLOADSTATUS.value = fileDownloadStatus;
  window.document.data.WCCTHROUGHPUTVALUE.value = throughputValue;
  window.document.data.WCCTHROUGHPUTSTATUS.value = throughputStatus;
  window.document.data.WCCSERVICELATENCYVALUE.value = serviceLatencyValue;
  window.document.data.WCCSERVICELATENCYSTATUS.value = serviceLatencyStatus;
  window.document.data.WCCPINGLATENCYVALUE.value = pingLatencyValue;
  window.document.data.WCCPINGLATENCYSTATUS.value = pingLatencyStatus;
  window.document.data.WCCPINGJITTERVALUE.value = pingJitterValue;
  window.document.data.WCCPINGJITTERSTATUS.value = pingJitterStatus;

  window.document.data.SPEED.value = 0;
  window.document.data.STARTTIME.value = startTime;
  window.document.data.ENDTIME.value = endTime;
  window.document.data.FILESIZE.value = bytesDownloaded;
  window.document.data.DURATION.value = duration;

  window.document.data.PACKETDROPTEST1.value = packetDropTest1;
  window.document.data.JITTERTEST1.value = jitterTest1;
  window.document.data.LATENCYTEST1.value = latencyTest1;

  window.document.data.DONE.value = 1;

  window.document.data.EXCEPTION.value = stackTrace;

  window.document.data.submit();
}

function submitErrorDetails(errorMessage, startTime, endTime) {
  //alert('submitPacketdropResults invoked !!! ');

  window.parent.f2.sendErrorDetails(errorMessage, startTime, endTime);
}
function sendErrorDetails(errorMessage, startTime, endTime) {
  if (
    !(window.document.data && window.document.data.DONE) ||
    window.document.data.DONE.value == 1
  )
    return;

  getInfo();

  window.document.data.BROWSER.value = escape(browser);
  window.document.data.OS.value = escape(os);
  window.document.data.VERSION.value = escape(ver);

  window.document.data.WCCFILEDOWNLOADVALUE.value = "";
  window.document.data.WCCFILEDOWNLOADSTATUS.value = errorMessage; //Temprorily send error message in WCCFILEDOWNLOADSTATUS
  window.document.data.WCCTHROUGHPUTVALUE.value = -1;
  window.document.data.WCCTHROUGHPUTSTATUS.value = "";
  window.document.data.WCCSERVICELATENCYVALUE.value = -1;
  window.document.data.WCCSERVICELATENCYSTATUS.value = "";
  window.document.data.WCCPINGLATENCYVALUE.value = -1;
  window.document.data.WCCPINGLATENCYSTATUS.value = "";
  window.document.data.WCCPINGJITTERVALUE.value = -1;
  window.document.data.WCCPINGJITTERSTATUS.value = "";

  window.document.data.SPEED.value = -1;
  window.document.data.STARTTIME.value = startTime;
  window.document.data.ENDTIME.value = endTime;
  window.document.data.FILESIZE.value = -1;
  window.document.data.DURATION.value = -1;

  window.document.data.DONE.value = 1;

  window.document.data.submit();
}
