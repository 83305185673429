import React, { useState,useEffect } from "react";
import AddressList from '../AddressList'
import Pagination from '../Pagination'
var currPage;

const AddressComponent = ({addressArray,totalAddress,showPostResultComp}) => {
    const [currentPage, setCurrentPage] = useState(1);
    
    const  [addressPerPage] =useState(10);

const indexofLastAddress=currentPage*addressPerPage;
const indexofFirstAddress=indexofLastAddress-addressPerPage;
    // var lastPageNo;
    // var indexofLastAddress;
    // var indexofFirstAddress;
    
    // var currentAddress=[];
    // var reminder = totalAddress % addressPerPage;

    // if (reminder >  0)
    // {
    //   lastPageNo = (totalAddress / addressPerPage) + 1;
    // }
    // else
    // {
    //    lastPageNo = totalAddress / addressPerPage;
       
    // }
    // lastPageNo=Math.floor(lastPageNo); 
    // console.log("last page is->",lastPageNo)
    // console.log("set currnt page page is->",currPage)
    // if (currPage == lastPageNo)
    // {
    //   console.log("if->",lastPageNo,totalAddress,reminder)
    //   indexofLastAddress = totalAddress;
    //   console.log("if lastindex->",totalAddress)
    //   indexofFirstAddress = indexofLastAddress-(reminder+1);
    //   console.log("if firstindex->",indexofFirstAddress)
    // }
    // else
    // {console.log("else->",lastPageNo,totalAddress,reminder)
    //   indexofLastAddress =currentPage *addressPerPage;
    //   console.log("if lastindex->",totalAddress)
    //   indexofFirstAddress = indexofLastAddress-addressPerPage;
    //   console.log("if firstindex->",indexofFirstAddress)
    // }
     const newAddressArray= addressArray.slice(0,totalAddress);
     console.log(newAddressArray)
    const currentAddress = newAddressArray.slice(indexofFirstAddress,indexofLastAddress);
    console.log("addres on current page are---->",currentAddress)

    const paginate=(pageNumber)=>
    {
    setCurrentPage(pageNumber);
    currPage = pageNumber;
    }
    
  return (
      <div>
          <Pagination addressPerPage={addressPerPage} totalAddress={totalAddress} paginate={paginate}/>
<AddressList addressArray={currentAddress} showPostResultComp={showPostResultComp}/>
      </div>
  );
};
export default AddressComponent;
