import React, { memo } from 'react';
import { withRouter } from "react-router";
import Box from "../../Components/Box";
import completeImage from "../../Assets/checked.png";
import downloadIcon from "../../Assets/downloadIcon.png";
import pingIcon from "../../Assets/pingIcon.png";
import uploadIcon from "../../Assets/uploadIcon.png";

const Details = (props) => (
    <div className="d-flex justify-content-center align-items-center">
        <div className="card space-for-header full-width" style={{ background: 'rgba(78, 32, 171, 0.8)', color: 'white' }}>
            <div className="card-body">
                <div className="row">
                    {/* full rocket section */}
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <h6>Basic Speed Test</h6>
                            </div>

                            <div className="col-6">
                                <img
                                    src={completeImage}
                                    
                                    style={{ width: '100%', maxWidth: 130 }}
                                />
                            </div>

                            <div className="col-6">
                                <div className="column columncss">
                                    <div
                                        className="col-sm-12 green green-css"
                                    >
                                        <span className="green-css-bb">Broadband</span><span> Speed</span> <span> Test</span>
                                    </div>
                                    <div
                                        className="col-sm-12 green green-css"
                                    >
                                        <span className="green-css-ex">Executed</span> <span className="green-css-su"> Successfully</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* feed boxes */}
                    <div className="col-12">
                        <div className="row">
                            <Box
                                name="PING"
                                value={props.ping}
                                unit="Ms"
                                img={pingIcon}
                                className="col-6"
                            />
                            <Box
                                name="DOWNLOAD"
                                value={props.download}
                                unit="Mbps"
                                img={downloadIcon}
                                className="col-6"
                            />
                            <Box
                                name="UPLOAD"
                                value={props.upload}
                                unit="Mbps"
                                img={uploadIcon}
                                className="col-6"
                            />
                        </div>
                        <div className="row circlecss circlecsss" style={{paddingLeft:20}}>
                        <a href="/details">
                        <button className="circle">
                          AGAIN
                        </button>
                      </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default withRouter (memo(Details));